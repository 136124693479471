import React, { useEffect } from "react";
import {
  Backdrop,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Grow,
  Hidden,
  Icon,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  CircularProgress,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { LOCALE } from "../../properties/Locale";
import Header from "../../common/Header";
import { Redirect } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import TelegramIcon from "@material-ui/icons/Telegram";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import clsx from "clsx";
import bgImg from "../../assets/img/productCurvyLines.png";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { CountryRepository } from "../../repositories/CountryRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { Transliteration } from "../../common/helpers/Transliteration";
import Footer from "../../common/Footer";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import {
  BasicDataValidator,
  BasicDataValidatorLegalEntity,
  ParentsDataValidator,
  PickUpWayValidator,
} from "./RequestBirthCertificateVaidator";
import axios from "axios";
import { RequestBirthCertificateRepository } from "../../repositories/RequestBirthCertificateRepository";
import { moment } from "../../App";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import PaymentComponent from "../payment/PaymentComponent";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { AuthService } from "../auth/AuthService";
import { AuthRepository } from "../auth/AuthRepository";
import { SETTINGS } from "../../properties/ApiProps";
import { notifyShowErrorMessage } from "../../common/actions/CommonActions";
import { NoteAdd } from "@material-ui/icons";
import { PropagateLoader } from "react-spinners";
import Upload from "../../common/components/Upload";
import { FilesRepository } from "../../repositories/FilesRepository";

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export default function RequestBirthCertificateComponent() {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");

  const [noFatherData, setNoFatherData] = React.useState(false);
  const [noMotherData, setNoMotherData] = React.useState(false);
  const [printDialog, setPrintDialog] = React.useState(false);
  const [generatedDocumentURL, setGeneratedDocumentURL] = React.useState();
  const [printDetails, setPrintDetails] = React.useState({
    paperType: "0",
  });
  const [generatedDocumentFile, setGeneratedDocumentFile] = React.useState();
  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [persistedRequest, setPersistedRequest] = React.useState();
  const [globalFormError, setGlobalFormError] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState();
  const [formData, setFormData] = React.useState({
    certForChild: "false",
    typeRequest: "0",
    firstName: "",
    lastName: "",
    country: {
      countryCode: "807",
      id: "0",
      name: "СЕВЕРНА МАКЕДОНИЈА",
      nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      nameEn: "REPUBLIC OF NORTH MACEDONIA",
      printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
      printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      printNameEn: "REPUBLIC OF NORTH MACEDONIA",
    },
    placeBirth: { name: "" },
    placeBirthManual: "",
    fatherName: "",
    fatherSurname: "",
    fatherEMBG: "",
    motherName: "",
    motherSurname: "",
    motherEMBG: "",
    pickupWayCert: "PICKUP_WAY_UVMK",
    registryArea: {},
    deliveryRegistryArea: {},
    deliveryPlace: { name: "" },
    deliveryAddress: "",
    dateBirth: moment(),
  });
  const [powerOfAttorneyScan, setPowerOfAttorneyScan] = React.useState([]);
  const [ecertProcessStatus, setEcertProcessStatus] =
    React.useState("NOT_GENERATED");
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");

  function getSteps() {
    if (currentUser?.proposerType === "ORGANIZATION") {
      return [
        LOCALE.purpose_and_type,
        LOCALE.basic_info,
        LOCALE.parents_info,
        LOCALE.pickup_way,
        LOCALE.sign_request,
        LOCALE.payment,
      ];
    } else {
      return [
        LOCALE.purpose_and_type,
        LOCALE.basic_info,
        LOCALE.parents_info,
        LOCALE.pickup_way,
        LOCALE.payment,
      ];
    }
  }
  const steps = getSteps();

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    let icons = {};
    if (currentUser?.proposerType)
      if (currentUser?.proposerType === "ORGANIZATION") {
        icons = {
          1: <SettingsIcon />,
          2: <InfoIcon />,
          3: <PermIdentityIcon />,
          4: <TelegramIcon />,
          5: <NoteAdd />,
          6: <CreditCardIcon />,
        };
      } else {
        icons = {
          1: <SettingsIcon />,
          2: <InfoIcon />,
          3: <PermIdentityIcon />,
          4: <TelegramIcon />,
          5: <CreditCardIcon />,
        };
      }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  useEffect(() => {
    if (!AuthService.getCurrentUser()) {
      if (window.location.search.includes("eid=true")) {
        window.location.href = `https://eid.mk/issue/wsfed?wa=wsignin1.0&wtrealm=https://e-portal.uvmk.gov.mk/&wctx=redirectUrl=${window.location.pathname}&id=passive&ru=%2f&wreply=https://e-portal.uvmk.gov.mk/login_token_eid`;
      } else {
        setRedirectTo("/login");
        return;
      }
    } else {
      UsersRepository.fetchCurrentUser().then(
        (res) => {
          setCurrentUser(res.data);
        },
        (err) => {
          if (err.message === "Network Error") {
            setGlobalFormError(ErrorMessageResolver.resolve(err));
          } else {
            AuthRepository.logout();
          }
        }
      );
      getActiveRegistryAreas();
    }
  }, []);

  useEffect(() => {
    if (noFatherData) {
      let data = { ...formData };
      data.fatherName = "НЕМА ПОДАТОЦИ";
      data.fatherSurname = "НЕМА ПОДАТОЦИ";
      setFormData(data);
    } else {
      let data = { ...formData };
      data.fatherName = "";
      data.fatherSurname = "";
      setFormData(data);
    }
  }, [noFatherData]);

  useEffect(() => {
    if (noMotherData) {
      let data = { ...formData };
      data.motherName = "НЕМА ПОДАТОЦИ";
      data.motherSurname = "НЕМА ПОДАТОЦИ";
      setFormData(data);
    } else {
      let data = { ...formData };
      data.motherName = "";
      data.motherSurname = "";
      setFormData(data);
    }
  }, [noMotherData]);

  useEffect(() => {
    if (
      currentUser != undefined &&
      currentUser.proposerType !== "ORGANIZATION"
    ) {
      let tmp = { ...formData };
      tmp.embg = currentUser?.embg;
      tmp.firstName = currentUser?.firstName;
      tmp.lastName = currentUser?.lastName;
      if (currentUser?.birthPlace != undefined)
        tmp.placeBirth = currentUser?.birthPlace;
      setFormData(tmp);
    }
  }, [currentUser]);

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  const handleChange = (name, value) => {
    let tmp = { ...formData };
    if (name === "country") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "placeBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryPlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryRegistryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.title === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  const validateBasicData = () => {
    if (!BasicDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await BasicDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateBasicDataLegalEntity = () => {
    if (!BasicDataValidatorLegalEntity.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await BasicDataValidatorLegalEntity.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateParentsData = () => {
    if (!ParentsDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await ParentsDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validatePickupWay = () => {
    if (!PickUpWayValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await PickUpWayValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const generateCertificate = (id) => {
    setGlobalFormError();
    setSaving(true);
    setPrintDialog(false);
    setGlobalFormError();
    axios({
      url: `${SETTINGS.API_URL}/requestBirthCertificate/generate/${id}`,
      method: "POST",
      params: {
        access_token: AuthService.getToken().access_token,
      },
      responseType: "blob",
    })
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setEcertProcessStatus("GENERATED");
        setGeneratedDocumentFile(file);
        setGeneratedDocumentURL(fileURL);
        setPrintDialog(true);
        setSaving(false);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        console.log(err.message);
        setSaving(false);
        setGlobalFormError("Грешка при генерирање на документот");
      });
  };

  const downloadGeneratedDocument = () => {
    window.open(generatedDocumentURL, "_blank");
  };

  const onUploadComponentClose = (files) => {
    setPowerOfAttorneyScan(files);
  };

  const signDocument = () => {
    if (typeof AuctaSignerApp === "function") {
      setPrintDialog(false);
      setLoading(true);
      console.log(window.ext);
      var reader = new FileReader();
      reader.readAsDataURL(generatedDocumentFile);
      reader.onloadend = function () {
        var base64data = reader.result.split(",")[1];
        // console.log(base64data);
        window.ext.signAndCertPDF(
          base64data,
          null,
          null,
          false,
          0,
          400,
          75,
          530,
          135,
          false,
          "null",
          (status, message) => {
            signAndCertCallback(status, message);
          }
        );
      };
    } else {
      setPrintDialog(false);
      setLoading(false);
      setGlobalFormError(
        "Ве молиме инсталирајте ги потребните алатки за дигитално потпишување"
      );
    }
  };

  const uploadSignedContent = (signedContent) => {
    FilesRepository.uploadSignedRequestCertificate(
      signedContent,
      persistedRequest.id,
      "BIRTHS"
    )
      .then((res) => {
        if (res.data === true) {
          setGlobalFormError();
          setEcertProcessStatus("SIGNED_AND_UPLOADED");
          setPrintDialog(false);
          setLoading(false);
        } else {
          setEcertProcessStatus("GENERATED");
          setPrintDialog(false);
          setGlobalFormError(
            "Грешка при прикачување на дигитално потпишаниот документ"
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setEcertProcessStatus("GENERATED");
        setPrintDialog(false);
        setGlobalFormError(
          "Грешка при прикачување на дигитално потпишаниот документ"
        );
        setLoading(false);
      });
  };

  function signAndCertCallback(status, message) {
    if (status === false && message.status === "Error") {
      if (message.error === "No valid certificates.") {
        setGlobalFormError(
          "Не е избран валиден сертификат за дигитално потпишување"
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else if (
        message.error.includes("The action was cancelled by the user.")
      ) {
        setGlobalFormError("Потпишувањето беше откажано на ваше барање");
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else {
        setGlobalFormError(
          "Грешка при потпишување на електронскиот документ. Ве молиме пријавете го овој проблем кај администраторот."
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      }
    } else if (status === true) {
      // console.log(status);
      // console.log(message);
      fetch(message.blobURL).then((r) => {
        r.blob().then((blob) => {
          // console.log(blob);
          let file = new Blob([blob], { type: "application/pdf" });
          uploadSignedContent(file);
        });
      });
    }
  }

  const handleNextStepLegalEntity = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 1) {
      validateBasicDataLegalEntity();
      if (!BasicDataValidatorLegalEntity.isValidSync(formData)) {
        return;
      } else {
        let dateBirthTmp = EmbgToDate.getDate(formData.embg);
        let tmp = { ...formData };
        tmp.dateBirth = dateBirthTmp;
        setFormData(tmp);
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      validateParentsData();
      if (!ParentsDataValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 3) {
      setLoading(true);
      validatePickupWay();
      if (!PickUpWayValidator.isValidSync(formData)) {
        setLoading(false);
        return;
      } else {
        setLoading(true);
        RequestBirthCertificateRepository.create(formData).then(
          (res) => {
            setPersistedRequest(res.data);
            generateCertificate(res.data.id);
            setLoading(false);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setLoading(false);
          }
        );
      }
    } else if (activeStep === 4) {
      setLoading(true);
      if (
        currentUser.proposerType === "ORGANIZATION" &&
        !currentUser?.institution?.institutionType == 2
      ) {
        FilesRepository.uploadPowerOfAttorneyScan(
          powerOfAttorneyScan,
          persistedRequest.id,
          "BIRTHS"
        ).then(
          (res) => {
            setLoading(false);
            setGlobalFormError(null);
            setActiveStep(activeStep + 1);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setGlobalFormError(ErrorMessageResolver.resolve(err));
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
        setGlobalFormError(null);
        setActiveStep(activeStep + 1);
      }
    }
    setFormDataErrors({});
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (formData.certForChild === "true") {
        let tmp = { ...formData };
        tmp.embg = "";
        tmp.firstName = "";
        tmp.lastName = "";
        if (currentUser?.gender === "MALE") {
          tmp.fatherName = currentUser?.firstName;
          tmp.fatherSurname = currentUser?.lastName;
          tmp.fatherEMBG = currentUser?.embg;
        } else {
          tmp.motherName = currentUser?.firstName;
          tmp.motherSurname = currentUser?.lastName;
          tmp.motherEMBG = currentUser?.embg;
        }
        setFormData(tmp);
        setActiveStep(activeStep + 1);
      } else {
        let tmp = { ...formData };
        tmp.embg = currentUser?.embg;
        tmp.firstName = currentUser?.firstName;
        tmp.lastName = currentUser?.lastName;
        tmp.fatherName = "";
        tmp.fatherSurname = "";
        tmp.fatherEMBG = "";
        tmp.motherName = "";
        tmp.motherSurname = "";
        tmp.motherEMBG = "";
        setFormData(tmp);
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 1) {
      validateBasicData();
      if (!BasicDataValidator.isValidSync(formData)) {
        return;
      } else {
        let dateBirthTmp = EmbgToDate.getDate(formData.embg);
        let tmp = { ...formData };
        tmp.dateBirth = dateBirthTmp;
        setFormData(tmp);
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      validateParentsData();
      if (!ParentsDataValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 3) {
      setLoading(true);
      validatePickupWay();
      if (!PickUpWayValidator.isValidSync(formData)) {
        setLoading(false);
        return;
      } else {
        setLoading(true);
        RequestBirthCertificateRepository.create(formData).then(
          (res) => {
            setPersistedRequest(res.data);
            setActiveStep(activeStep + 1);
            setLoading(false);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setLoading(false);
          }
        );
      }
    }
    setFormDataErrors({});
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} style={{ marginBottom: "20px" }}>
              <Typography variant="h5">{LOCALE.req_birth_cert}</Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: "0px" }}
                    display="flex"
                    alignItems="center"
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      backgroundColor: "rgb(212 212 212 / 0.85)",
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "20px",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      <strong>{steps[activeStep]}</strong>
                    </Typography>
                  </Grid>
                  {globalFormError && (
                    <React.Fragment>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                      <Grid item xs={12} md={6}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                    </React.Fragment>
                  )}
                  {activeStep === 0 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={2} sm={2} xs={false}></Grid>
                        {AuthService.getCurrentUser()?.proposerType ===
                          "ORGANIZATION" && (
                          <Grid item mx={3} sm={3} xs={false}></Grid>
                        )}
                        {AuthService.getCurrentUser()?.proposerType !==
                          "ORGANIZATION" && (
                          <Grid item md={4} sm={4} xs={12}>
                            <FormControl
                              component="fieldset"
                              style={{ paddingLeft: "20px" }}
                            >
                              <FormLabel
                                component="legend"
                                style={{ marginBottom: "15px" }}
                              >
                                {LOCALE.intended_for}:
                              </FormLabel>
                              <RadioGroup
                                aria-label="purpose"
                                name="purpose"
                                size="small"
                                value={formData.certForChild}
                                onChange={(e) =>
                                  handleChange("certForChild", e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="false"
                                  control={<Radio size="small" />}
                                  label={LOCALE.personaly}
                                />
                                <FormControlLabel
                                  value="true"
                                  control={<Radio size="small" />}
                                  label={LOCALE.child}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        )}
                        <Hidden smUp>
                          <Grid item xs={12}>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            ></Divider>
                          </Grid>
                        </Hidden>
                        <Grid item md={4} sm={4} xs={12}>
                          <FormControl
                            component="fieldset"
                            style={{ paddingLeft: "20px" }}
                          >
                            <FormLabel
                              component="legend"
                              style={{ marginBottom: "15px" }}
                            >
                              {LOCALE.certificateType}:
                            </FormLabel>
                            <RadioGroup
                              aria-label="typeRequest"
                              name="typeRequest"
                              value={formData.typeRequest}
                              onChange={(e) =>
                                handleChange("typeRequest", e.target.value)
                              }
                              size="small"
                            >
                              <FormControlLabel
                                value="0"
                                control={<Radio size="small" />}
                                label={LOCALE.regular_cert}
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio size="small" />}
                                label={LOCALE.internationale_cert}
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio size="small" />}
                                label={LOCALE.multilingual_cert}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={4} xs={false}></Grid>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.embg}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange("embg", e.target.value)
                                }
                                value={formData?.embg}
                                error={formDataErrors.embg !== undefined}
                                helperText={formDataErrors.embg}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.firstName}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "firstName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.firstName}
                                error={formDataErrors.firstName !== undefined}
                                helperText={formDataErrors.firstName}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.lastName}
                                size="small"
                                fullWidth
                                value={formData?.lastName}
                                error={formDataErrors.lastName !== undefined}
                                helperText={formDataErrors.lastName}
                                onChange={(e) =>
                                  handleChange(
                                    "lastName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                variant="outlined"
                              ></TextField>
                            </Grid>{" "}
                            <Grid item xs={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                autoComplete={false}
                                size="small"
                                value={
                                  formData.country.name
                                    ? formData.country.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("country", newValue);
                                }}
                                inputValue={countrySearch}
                                onInputChange={(event, newInputValue) => {
                                  setCountrySearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={countries?.map(
                                  (country) => country.name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.country_born}
                                    fullWidth
                                    error={formDataErrors.country !== undefined}
                                    helperText={formDataErrors.country}
                                    onFocus={onFocus}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                label={`${LOCALE.country_born} (SQ)`}
                                value={formData?.country?.nameAl ?? ""}
                              />
                            </Grid>
                            {formData.country.id === "0" ? (
                              <>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    noOptionsText={LOCALE.noOptionsText}
                                    size="small"
                                    value={
                                      formData.placeBirth.name
                                        ? formData.placeBirth.name
                                        : ""
                                    }
                                    onChange={(event, newValue) => {
                                      handleChange("placeBirth", newValue);
                                    }}
                                    inputValue={placeSearch}
                                    onInputChange={(event, newInputValue) => {
                                      setPlaceSearch(
                                        Transliteration.toUpperCyrilic(
                                          newInputValue
                                        )
                                      );
                                    }}
                                    options={places?.map((place) => place.name)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={LOCALE.place_born}
                                        fullWidth
                                        error={
                                          formDataErrors.placeBirth !==
                                          undefined
                                        }
                                        helperText={formDataErrors.placeBirth}
                                        onFocus={onFocus}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    label={`${LOCALE.place_born} (SQ)`}
                                    value={formData?.placeBirth?.nameAl ?? ""}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.place_born}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    error={
                                      formDataErrors.placeBirthManual !==
                                      undefined
                                    }
                                    helperText={formDataErrors.placeBirthManual}
                                    onChange={(e) => {
                                      handleChange(
                                        "placeBirthManual",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    value={formData?.placeBirthManual}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    error={formDataErrors?.registryArea}
                                  >
                                    <InputLabel>
                                      {LOCALE.registry_area_of_birth_record}
                                    </InputLabel>
                                    <Select
                                      size="small"
                                      value={formData.registryArea}
                                      onChange={(e) =>
                                        handleChange(
                                          "registryArea",
                                          e.target.value
                                        )
                                      }
                                      label={
                                        LOCALE.registry_area_of_birth_record
                                      }
                                    >
                                      {registryAreas &&
                                        registryAreas.map((regArea, index) => (
                                          <MenuItem
                                            key={index}
                                            value={regArea}
                                            size="small"
                                          >
                                            {regArea.title}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    {formDataErrors?.registryArea && (
                                      <FormHelperText error={true}>
                                        {formDataErrors.registryArea}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                  {formDataErrors?.deliveryRegistryArea && (
                                    <FormHelperText error={true}>
                                      {formDataErrors.deliveryRegistryArea}
                                    </FormHelperText>
                                  )}
                                </Grid>
                                <Grid xs={12} item>
                                  <TextField
                                    label={`${LOCALE.registry_area_of_birth_record} (SQ)`}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    disabled={true}
                                    value={
                                      formData?.registryArea?.titleAl ?? ""
                                    }
                                  />
                                </Grid>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={4} xs={false}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 2 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={2} xs={false}></Grid>
                        <Grid item md={8} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.mother_name}
                                size="small"
                                fullWidth
                                variant="outlined"
                                disabled={noMotherData}
                                onChange={(e) =>
                                  handleChange(
                                    "motherName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.motherName}
                                error={formDataErrors.motherName !== undefined}
                                helperText={formDataErrors.motherName}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.mother_surname}
                                size="small"
                                fullWidth
                                variant="outlined"
                                disabled={noMotherData}
                                onChange={(e) =>
                                  handleChange(
                                    "motherSurname",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.motherSurname}
                                error={
                                  formDataErrors.motherSurname !== undefined
                                }
                                helperText={formDataErrors.motherSurname}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={noMotherData}
                                    disabled={
                                      formData.certForChild === "true" &&
                                      currentUser.gender === "FEMALE"
                                    }
                                    onChange={(e) =>
                                      setNoMotherData(e.target.checked)
                                    }
                                  />
                                }
                                label={LOCALE.no_mother_data}
                              />
                            </Grid>
                            {formData.certForChild === "true" && (
                              <Grid item xs={12}>
                                <TextField
                                  label={LOCALE.mother_embg}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  disabled={noMotherData}
                                  onChange={(e) =>
                                    handleChange(
                                      "motherEMBG",

                                      e.target.value
                                    )
                                  }
                                  value={formData?.motherEMBG}
                                  error={
                                    formDataErrors.motherEMBG !== undefined
                                  }
                                  helperText={formDataErrors.motherEMBG}
                                ></TextField>
                              </Grid>
                            )}
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.father_name}
                                size="small"
                                fullWidth
                                disabled={noFatherData}
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "fatherName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.fatherName}
                                error={formDataErrors.fatherName !== undefined}
                                helperText={formDataErrors.fatherName}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.father_surname}
                                size="small"
                                disabled={noFatherData}
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "fatherSurname",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.fatherSurname}
                                error={
                                  formDataErrors.fatherSurname !== undefined
                                }
                                helperText={formDataErrors.fatherSurname}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={
                                      formData.certForChild === "true" &&
                                      currentUser.gender === "MALE"
                                    }
                                    checked={noFatherData}
                                    onChange={(e) =>
                                      setNoFatherData(e.target.checked)
                                    }
                                  />
                                }
                                label={LOCALE.no_father_data}
                              />
                            </Grid>
                            {formData.certForChild === "true" && (
                              <Grid item xs={12}>
                                <TextField
                                  label={LOCALE.father_embg}
                                  size="small"
                                  fullWidth
                                  disabled={noFatherData}
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleChange(
                                      "fatherEMBG",

                                      e.target.value
                                    )
                                  }
                                  value={formData?.fatherEMBG}
                                  error={
                                    formDataErrors.fatherEMBG !== undefined
                                  }
                                  helperText={formDataErrors.fatherEMBG}
                                ></TextField>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 3 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={4} xs={false}></Grid>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={2}>
                            {currentUser?.proposerType == "ORGANIZATION" &&
                              currentUser?.institution?.institutionType ==
                                2 && (
                                <Grid item xs={12}>
                                  <TextField
                                    label={"Број на предмет (правен интерес)"}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) =>
                                      handleChange(
                                        "legalInterestNumber",
                                        e.target.value
                                      )
                                    }
                                    value={formData?.legalInterestNumber}
                                    error={
                                      formDataErrors.legalInterestNumber !==
                                      undefined
                                    }
                                    helperText={
                                      formDataErrors.legalInterestNumber
                                    }
                                  ></TextField>
                                </Grid>
                              )}
                            <Grid item xs={12}>
                              <FormControl
                                disabled={loading}
                                variant="outlined"
                                fullWidth
                                size="small"
                              >
                                <InputLabel>{LOCALE.pickup_way}</InputLabel>
                                <Select
                                  value={formData.pickupWayCert}
                                  onChange={(e) =>
                                    handleChange(
                                      "pickupWayCert",
                                      e.target.value
                                    )
                                  }
                                  label={LOCALE.pickup_way}
                                >
                                  <MenuItem value="PICKUP_WAY_UVMK">
                                    {LOCALE.pickup_way_uvmk}
                                  </MenuItem>
                                  {currentUser?.proposerType !=
                                    "ORGANIZATION" && (
                                    <MenuItem value="PICKUP_WAY_POSTOFFICE">
                                      {LOCALE.pickup_way_postoffice}
                                    </MenuItem>
                                  )}
                                  {(currentUser?.nationalEid !== null ||
                                    currentUser?.proposerType ==
                                      "ORGANIZATION") &&
                                    formData.typeRequest != 1 && (
                                      <MenuItem value="PICKUP_WAY_ELECTRONIC_CERT">
                                        {LOCALE.pickup_way_el}
                                      </MenuItem>
                                    )}
                                </Select>
                              </FormControl>
                            </Grid>
                            {formData.pickupWayCert === "PICKUP_WAY_UVMK" && (
                              <>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    noOptionsText={LOCALE.noOptionsText}
                                    size="small"
                                    disabled={loading}
                                    value={
                                      formData?.deliveryRegistryArea?.title
                                        ? formData?.deliveryRegistryArea?.title
                                        : ""
                                    }
                                    onChange={(event, newValue) => {
                                      handleChange(
                                        "deliveryRegistryArea",
                                        newValue
                                      );
                                    }}
                                    inputValue={deliveryRegistryAreaSearch}
                                    onInputChange={(event, newInputValue) => {
                                      setDeliveryRegistryAreaSearch(
                                        Transliteration.toUpperCyrilic(
                                          newInputValue
                                        )
                                      );
                                    }}
                                    options={registryAreas?.map(
                                      (regArea) => regArea.title
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={LOCALE.pickup_way_reg_area}
                                        fullWidth
                                        error={
                                          formDataErrors.deliveryRegistryArea
                                        }
                                        helperText={
                                          formDataErrors.deliveryRegistryArea
                                        }
                                        onFocus={onFocus}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    label={`${LOCALE.pickup_way_reg_area} (SQ)`}
                                    size="small"
                                    fullWidth
                                    disabled={true}
                                    variant="outlined"
                                    value={
                                      formData?.deliveryRegistryArea?.titleAl ??
                                      ""
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                            {formData.pickupWayCert ===
                              "PICKUP_WAY_POSTOFFICE" && (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    disabled={loading}
                                    noOptionsText={LOCALE.noOptionsText}
                                    size="small"
                                    value={
                                      formData.deliveryPlace.name
                                        ? formData.deliveryPlace.name
                                        : ""
                                    }
                                    onChange={(event, newValue) => {
                                      handleChange("deliveryPlace", newValue);
                                    }}
                                    inputValue={placeSearch}
                                    onInputChange={(event, newInputValue) => {
                                      setPlaceSearch(
                                        Transliteration.toUpperCyrilic(
                                          newInputValue
                                        )
                                      );
                                    }}
                                    options={places?.map((place) => place.name)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={LOCALE.delivery_place}
                                        fullWidth
                                        error={
                                          formDataErrors.deliveryPlace !==
                                          undefined
                                        }
                                        helperText={
                                          formDataErrors.deliveryPlace
                                        }
                                        onFocus={onFocus}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>
                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.delivery_place} (SQ)`}
                                      value={
                                        formData?.deliveryPlace?.nameAl ?? ""
                                      }
                                    />
                                  </Grid>
                                )}

                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.delivery_address}
                                    size="small"
                                    fullWidth
                                    disabled={loading}
                                    variant="outlined"
                                    onChange={(e) =>
                                      handleChange(
                                        "deliveryAddress",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      )
                                    }
                                    value={formData?.deliveryAddress}
                                    error={
                                      formDataErrors.deliveryAddress !==
                                      undefined
                                    }
                                    helperText={formDataErrors.deliveryAddress}
                                  ></TextField>
                                </Grid>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {currentUser?.proposerType === "ORGANIZATION" ? (
                    activeStep === 4 ? (
                      <>
                        <Grid item xs={2} />
                        <Grid item xs={8} style={{ textAlign: "center" }}>
                          {saving && (
                            <>
                              <CircularProgress color="secondary" />
                              <p>
                                <strong>Генерирање...</strong>
                              </p>
                            </>
                          )}
                          {ecertProcessStatus !== "NOT_GENERATED" && (
                            <>
                              <Grid container spacing={2}>
                                {ecertProcessStatus ===
                                "SIGNED_AND_UPLOADED" ? (
                                  <Grid item xs={12}>
                                    <Alert severity="success">
                                      <>
                                        <span>
                                          {LOCALE.electronic_doc_signed_success}
                                        </span>
                                      </>
                                    </Alert>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12}>
                                    <Alert severity="info">
                                      <>
                                        <span>
                                          {
                                            LOCALE.electronic_doc_generated_success
                                          }
                                        </span>
                                      </>
                                    </Alert>
                                  </Grid>
                                )}
                                <Grid item xs={12}>
                                  <Button
                                    color="secondary"
                                    onClick={() => {
                                      downloadGeneratedDocument();
                                    }}
                                    fullWidth
                                    size="small"
                                  >
                                    <Icon style={{ marginRight: "10px" }}>
                                      description
                                    </Icon>
                                    <strong>{LOCALE.gen_doc_preview}</strong>
                                  </Button>
                                </Grid>

                                <Grid item xs={12}>
                                  <Button
                                    color="primary"
                                    size="small"
                                    disabled={
                                      ecertProcessStatus ===
                                      "SIGNED_AND_UPLOADED"
                                    }
                                    variant="contained"
                                    onClick={() => {
                                      signDocument();
                                    }}
                                  >
                                    {LOCALE.sign}
                                  </Button>
                                </Grid>

                                {currentUser?.proposerType === "ORGANIZATION" &&
                                  currentUser?.institution?.institutionType !=
                                    2 && (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ textAlign: "center" }}
                                    >
                                      <br></br>
                                      <Alert severity="info">
                                        <AlertTitle>
                                          {LOCALE.warning}!
                                        </AlertTitle>
                                        <>
                                          <span>
                                            {LOCALE.upload_polnomosno}
                                          </span>
                                        </>
                                      </Alert>
                                      <br></br>
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          style={{
                                            alignItems: "center",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Upload
                                            onUploadComponentClose={(files) =>
                                              onUploadComponentClose(files)
                                            }
                                          ></Upload>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )}
                              </Grid>
                            </>
                          )}
                          <Grid item xs={2} />
                        </Grid>
                      </>
                    ) : activeStep === 5 ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid md={2} xs={false}></Grid>
                          <Grid md={8} xs={12}>
                            <PaymentComponent
                              reqBirthCert={persistedRequest}
                            ></PaymentComponent>
                          </Grid>
                          <Grid md={2} xs={false}></Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  {activeStep === 4 &&
                    currentUser?.proposerType !== "ORGANIZATION" && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid md={2} xs={false}></Grid>
                          <Grid md={8} xs={12}>
                            <PaymentComponent
                              reqBirthCert={persistedRequest}
                            ></PaymentComponent>
                          </Grid>
                          <Grid md={2} xs={false}></Grid>
                        </Grid>
                      </Grid>
                    )}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Divider></Divider>

                    {currentUser?.proposerType !== "ORGANIZATION" &&
                      activeStep !== 0 &&
                      activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {currentUser?.proposerType === "ORGANIZATION" &&
                      activeStep !== 0 &&
                      activeStep !== steps.length - 1 &&
                      activeStep !== steps.length - 2 && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={
                          loading ||
                          saving ||
                          (ecertProcessStatus !== "SIGNED_AND_UPLOADED" &&
                            activeStep === 4) ||
                          (activeStep === 4 &&
                            currentUser.proposerType === "ORGANIZATION" &&
                            currentUser.institution.institutionType != 2 &&
                            powerOfAttorneyScan?.length === 0)
                        }
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => {
                          if (
                            AuthService.getCurrentUser()?.proposerType ===
                            "ORGANIZATION"
                          )
                            handleNextStepLegalEntity();
                          else handleNextStep();
                        }}
                      >
                        {LOCALE.next}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() =>
                          setRedirectTo(
                            "/births/requestBirthCertificate/" +
                              persistedRequest.id
                          )
                        }
                      >
                        {LOCALE.requestPreview}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => {
                          window.location.href = `${
                            SETTINGS.API_URL
                          }/payment/initialization?reasonType=birth&rel=${
                            persistedRequest.id
                          }&access_token=${
                            AuthService.getToken().access_token
                          }`;
                        }}
                      >
                        {LOCALE.iAgree}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
