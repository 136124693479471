import MomentUtils from "@date-io/moment";
import {
  Divider,
  FormControl,
  Grid,
  Hidden,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Autocomplete } from "@material-ui/lab";
import { moment } from "../../App";
import { Transliteration } from "../../common/helpers/Transliteration";
import MuiPhoneNumber from "material-ui-phone-number";

export default function RequestMarriageWizardSpouseDetails({
  handleChange,
  getSpouseIndex,
  formData,
  formDataErrors,
  countrySearch,
  setCountrySearch,
  onFocus,
  countries,
  defaultCountry,
  setPlaceSearch,
  placeSearch,
  places,
  spouseType,
  citizenshipSearch,
  setCitizenshipSearch,
  educations,
  setEducationSearch,
  educationSearch,
}) {
  return (
    <React.Fragment>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <Grid container>
          <Hidden smUp>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              ></Divider>
            </Grid>
          </Hidden>
          <Grid item xs={false} md={3}></Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.embg}
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="number"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].embg`,
                      e.target.value
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]?.embg
                      ? formData?.spousesData[getSpouseIndex(spouseType)]?.embg
                      : ""
                  }
                  error={formDataErrors["spousesData[0].embg"] !== undefined}
                  helperText={formDataErrors["spousesData[0].embg"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.firstName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].name`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]?.name
                      ? formData?.spousesData[getSpouseIndex(spouseType)]?.name
                      : ""
                  }
                  error={formDataErrors["spousesData[0].name"] !== undefined}
                  helperText={formDataErrors["spousesData[0].name"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.lastName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].surname`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]?.surname
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.surname
                      : ""
                  }
                  error={formDataErrors["spousesData[0].surname"] !== undefined}
                  helperText={formDataErrors["spousesData[0].surname"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.surnameMarriage}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].surnameMarriage`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.surnameMarriage
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.surnameMarriage
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].surnameMarriage"] !==
                    undefined
                  }
                  helperText={formDataErrors["spousesData[0].surnameMarriage"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      disableFuture
                      size="small"
                      format="DD/MM/YYYY"
                      inputVariant="outlined"
                      id="bride-birth-date"
                      label={LOCALE.birthDate}
                      value={
                        formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.dateBirth
                          ? formData?.spousesData[getSpouseIndex(spouseType)]
                              ?.dateBirth
                          : null
                      }
                      onChange={(date) =>
                        handleChange(
                          `spousesData[${getSpouseIndex(
                            spouseType
                          )}].dateBirth`,
                          date
                        )
                      }
                      error={
                        formDataErrors["spousesData[0].dateBirth"] !== undefined
                      }
                      helperText={
                        formDataErrors["spousesData[0].dateBirth"]
                          ? "Невалиден датум"
                          : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  size="small"
                  label={LOCALE.contact_phone}
                  fullWidth={true}
                  error={
                    formDataErrors["spousesData[0].phoneNumber"] !== undefined
                  }
                  helperText={formDataErrors["spousesData[0].phoneNumber"]}
                  variant="outlined"
                  defaultCountry={"mk"}
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.phoneNumber
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.phoneNumber
                      : null
                  }
                  onChange={(e) => {
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].phoneNumber`,
                      e
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  autoComplete={false}
                  size="small"
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]?.country
                      ?.name
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.country?.name
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].country`,
                      countries.find((country) => country.name === newValue)
                    );
                  }}
                  inputValue={countrySearch}
                  onInputChange={(event, newInputValue) => {
                    setCountrySearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={countries?.map((country) => country.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.country_born}
                      fullWidth
                      error={
                        formDataErrors["spousesData[0].country.id"] !==
                        undefined
                      }
                      helperText={formDataErrors["spousesData[0].country.id"]}
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {LOCALE && LOCALE.getLanguage() === "al" && (
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    label={`${LOCALE.country_born} (SQ)`}
                    value={
                      formData?.spousesData[getSpouseIndex(spouseType)]?.country
                        ?.nameAl ?? ""
                    }
                  />
                </Grid>
              )}

              {formData?.spousesData[getSpouseIndex(spouseType)]?.country
                ?.id === defaultCountry.id ? (
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    size="small"
                    value={
                      formData?.spousesData[getSpouseIndex(spouseType)]?.place
                        ?.name
                        ? formData?.spousesData[getSpouseIndex(spouseType)]
                            ?.place?.name
                        : null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        `spousesData[${getSpouseIndex(spouseType)}].place`,
                        places.find((place) => place.name === newValue)
                      );
                    }}
                    inputValue={placeSearch}
                    onInputChange={(event, newInputValue) => {
                      setPlaceSearch(
                        Transliteration.toUpperCyrilic(newInputValue)
                      );
                    }}
                    options={places?.map((place) => place.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LOCALE.place_born}
                        fullWidth
                        error={
                          formDataErrors["spousesData[0].place"] !== undefined
                        }
                        helperText={formDataErrors["spousesData[0].place"]}
                        onFocus={onFocus}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={LOCALE.place_born}
                      size="small"
                      variant="outlined"
                      autoComplete="false"
                      fullWidth={true}
                      onChange={(e) => {
                        handleChange(
                          `spousesData[${getSpouseIndex(
                            spouseType
                          )}].placeBirth`,
                          Transliteration.toUpperCyrilic(e.target.value)
                        );
                      }}
                      value={
                        formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.placeBirth
                          ? formData?.spousesData[getSpouseIndex(spouseType)]
                              ?.placeBirth
                          : ""
                      }
                      error={
                        formDataErrors["spousesData[0].placeBirth"] !==
                        undefined
                      }
                      helperText={formDataErrors["spousesData[0].placeBirth"]}
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={6}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.citizenship?.name
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.citizenship?.id === defaultCountry.id
                        ? "МАКЕДОНСКО/..."
                        : formData?.spousesData[getSpouseIndex(spouseType)]
                            ?.citizenship?.name
                      : null
                  }
                  onChange={(event, newValue) => {
                    // console.log(newValue);
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].citizenship`,
                      newValue === "МАКЕДОНСКО/..."
                        ? defaultCountry
                        : countries.find((country) => country.name === newValue)
                    );
                  }}
                  inputValue={citizenshipSearch}
                  onInputChange={(event, newInputValue) => {
                    setCitizenshipSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={countries?.map((country) =>
                    country.id == defaultCountry.id
                      ? "МАКЕДОНСКО/..."
                      : country.name
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.citizenship}
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors["spousesData[0].citizenship.id"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["spousesData[0].citizenship.id"]
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.address_living}
                  size="small"
                  variant="outlined"
                  autoComplete="false"
                  fullWidth={true}
                  onChange={(e) => {
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].addressLiving`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    );
                  }}
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.addressLiving
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.addressLiving
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].addressLiving"] !== undefined
                  }
                  helperText={formDataErrors["spousesData[0].addressLiving"]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]?.education
                      ?.title
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.education?.title
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].education`,
                      educations.find(
                        (education) => education.title === newValue
                      )
                    );
                  }}
                  inputValue={educationSearch}
                  onInputChange={(event, newInputValue) => {
                    setEducationSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={educations?.map((education) => education.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.education}
                      fullWidth
                      error={
                        formDataErrors["spousesData[0].education.id"] !==
                        undefined
                      }
                      helperText={formDataErrors["spousesData[0].education.id"]}
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.schoolName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(spouseType)}].schoolName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.schoolName
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.schoolName
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].schoolName"] !== undefined
                  }
                  helperText={formDataErrors["spousesData[0].schoolName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.fatherFirstName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].fatherFirstName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.fatherFirstName
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.fatherFirstName
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].fatherFirstName"] !==
                    undefined
                  }
                  helperText={formDataErrors["spousesData[0].fatherFirstName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.motherFirstName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].motherFirstName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.motherFirstName
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.motherFirstName
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].motherFirstName"] !==
                    undefined
                  }
                  helperText={formDataErrors["spousesData[0].motherFirstName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.fatherMaidenLastname}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].fatherMaidenLastname`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.fatherMaidenLastname
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.fatherMaidenLastname
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].fatherMaidenLastname"] !==
                    undefined
                  }
                  helperText={
                    formDataErrors["spousesData[0].fatherMaidenLastname"]
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.motherMaidenLastname}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].motherMaidenLastname`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.motherMaidenLastname
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.motherMaidenLastname
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].motherMaidenLastname"] !==
                    undefined
                  }
                  helperText={
                    formDataErrors["spousesData[0].motherMaidenLastname"]
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.fatherLastName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].fatherLastName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.fatherLastName
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.fatherLastName
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].fatherLastName"] !==
                    undefined
                  }
                  helperText={formDataErrors["spousesData[0].fatherLastName"]}
                ></TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.motherLastName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        spouseType
                      )}].motherLastName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex(spouseType)]
                      ?.motherLastName
                      ? formData?.spousesData[getSpouseIndex(spouseType)]
                          ?.motherLastName
                      : ""
                  }
                  error={
                    formDataErrors["spousesData[0].motherLastName"] !==
                    undefined
                  }
                  helperText={formDataErrors["spousesData[0].motherLastName"]}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
