import LocalizedStrings from "react-localization";

export const LOCALE = new LocalizedStrings({
  mk: {
    "email already exist": "Меил адресата е веќе регистрирана",
    "you are not allowed to edit user data because the user is authorized with national eid":
      "Не е дозволено да менувате детали на вашиот профил бидејќи сте најавени со еИД (eid.mk).",
    "electronic international birth certificate is not allowed yet":
      "Во моментов УВМК не издава електронски интернационални сертификати од матичните книги на родени. Можете да изберете опција за подигнување на шалтерите на УВМК или по пошта.",
    "electronic international death certificate is not allowed yet":
      "Во моментов УВМК не издава електронски интернационални сертификати од матичните книги на умрени. Можете да изберете опција за подигнување на шалтерите на УВМК или по пошта.",
    "electronic international marriage certificate is not allowed yet":
      "Во моментов УВМК не издава електронски интернационални сертификати од матичните книги на венчани. Можете да изберете опција за подигнување на шалтерите на УВМК или по пошта.",
    "place birth is required field": "Место на раѓање е задолжително поле",
    "place birth manual is required field":
      "Место на раѓање е задолжително поле",
    "place death is required field": "Место на смртта е задолжително поле",
    "address death is required field": "Адреса на смртта е задолжително поле",
    "death place manual is required field":
      "Место на смртта е задолжително поле",
    registry_area_of_birth_record: "Матично подрачје во кое е направен уписот",
    "registry area is required field":
      "Матично подрачје во кое е направен уписот е задолжително поле",
    "delivery registry area is required field":
      "Матично подрачје во кое би се подигнал изводот е задолжително поле",
    "pickup way cert must be UVMK":
      "Начин на подигање на изводот мора да биде на шалтер во УВМК",
    "passport or id number is required":
      "Лична карта или пасош на поднесителот на барањето и датум на важност е задолжитено поле",
    "invalid passport issuing date": "Погрешен датум на издавање на пасошот",
    "invalid passport expiry date": "Погрешен датум на истекување на пасошот",
    "invalid id issuing date": "Погрешен датум на издавање на лична карта",
    "invalid id expiry date": "Погрешен датум на истекување на лична карта",
    "placeLocation is required": "Место е задолжително поле",
    "placeLivingManual is required": "Место е задолжително поле",
    "certificate is required": "Сертификат е задолжително поле",
    "error while reading certificate": "Грешка при вчитување на сертификатот",
    "delivery registry area is not bilingual":
      "Матичното подрачје во кое би се подигнал изводот не е двојазично",
    "certificate already exist":
      "Сертификатот кој го внесовте е веќе регистриран",
    "registry area is not bilingual":
      "Матичното подрачје во кое е направен уписот не е надлежно да издава двојазични изводи",
    "person died in the current year and not found in our system":
      "Лицето сеуште не е пријавено за починато на шалтерите на УВМК",
    "request death certificate not found":
      "Барањето за извод за умрени не е пронајдено",
    "you are not authorized to change this request":
      "Немате привилегии за промена на ова барање",
    "you cannot change paid request":
      "Не можете да промените веќе платено барање",
    "new certificate cannot be same old":
      "Новиот сертификат не може да биде ист како стариот",
    "certificate is required field": "Сертификат е задолжително поле",
    dateDeath: "Датум на смртта",
    number: "Број ",
    processing: "Се обработува",
    purposer_info: "Податоци за поднесителот",
    verifying_email: "Потврдување на вашата е-пошта",
    forbidden_h2: "Пренасочување кон страница за најава за ",
    "embg is not valid": "Невалиден ЕМБГ",
    email_verified: "E-поштата е потврдена",
    "password changed": "Успешно променета лозинка",
    "certificate changed": "Успешно променет сертификат",
    "token not valid": "Невалиден линк за промена на лозинка",
    "token expired or used":
      "Линкот за промена на лозинка е истечен или искористен",
    payment_policy:
      "Ве молиме внимателно прочитајте ги УСЛОВИТЕ ЗА КОРИСТЕЊЕ НА УСЛУГАТА ЗА ЕЛЕКТРОНСКО ПЛАЌАЊЕ НА СМЕТКИ СО ПЛАТЕЖНИ КАРТИЧКИ ПРЕКУ ПОРТАЛОТ. Доколку се согласувате со Условите, одберете го копчето СЕ СОГЛАСУВАМ, по што ќе ви биде отворена страница од НЛБ Тутунска Банка во која ќе треба да ги внесете податоците за вашата платежна картичка за да го извршите плаќањето.",
    BASIC: "ОСНОВЕН ИЗВОД",
    INTERNATIONAL: "ИНТЕРНАЦИОНАЛЕН ИЗВОД",
    MULTILINGUAL: "ДВОЈАЗИЧЕН ИЗВОД",
    priceList: "Ценовник",
    institution: "Институција",
    institutionType: "Тип на институција",
    title: "Назив",
    edb: "ЕДБ",
    embg_doesnt_match: "ЕМБГ не се совпаѓа со најавениот корисник",
    is_adult_message: "Лицето е полнолетно",
    pickup_way_reg_area: "Матично подрачје во кое би се подигнал изводот",
    delivery_address: "Адреса на достава на изводот",
    delivery_place: "Место за достава на изводот",
    mother_name: "Име на мајка",
    mother_surname: "Презиме на мајка",
    mother_embg: "ЕМБГ на мајка",
    father_embg: "ЕМБГ на татко",
    father_name: "Име на татко",
    father_surname: "Презиме на татко",
    purpose_and_type: "Намена и тип на извод",
    basic_info: "Основни податоци",
    person_death_info: "Податоци за починатото лице",
    death_info: "Податоци за смртта",
    deathDate: "Датум на смртта",
    deathCountry: "Држава на смртта",
    deathPlace: "Место на смртта",
    deathAddress: "Адреса на смртта",
    parents_info: "Податоци за родителите",
    pickup_way: "Подигнување на изводот",
    sign_request: "Потпиши барање",
    upload_power_of_attorney: "Прикачи полномошно",
    pickup_way_uvmk: "Во просториите на УВМК (на шалтер)",
    pickup_way_postoffice: "Преку пошта",
    pickup_way_el: "Електронски извод",
    payment: "Плаќање",
    req_free_martial_status: "Барање за издавање на слободна брачна состојба",
    req_birth_cert:
      "Барање за издавање на извод од матичната книга на родените",
    req_death_cert:
      "Барање за издавање на извод од матичната книга на умрените",
    personaly: "Лично за себе",
    child: "Малолетно дете",
    regular_cert: "Основен извод",
    internationale_cert: "Интернационален извод",
    multilingual_cert: "Двојазичен извод",

    next: "Следно",
    prev: "Претходно",

    doesnt_match: "Лозинките не се совпаѓаат",
    incorrect_format: "Внесете валиден формат",

    uvmk: "Управа за водење на матичните книги",
    ministry_of_justice: "Министерство за правда",

    request_birth_certificate: "Извод од матична книга на родени",
    request_birth_certificate_full:
      "Барање за извод од матичната книга на родените",
    request_birth_certificate_description:
      "Поднесете електронско барање за издавање на извод од матичната книга на родените. На располагање ви се 3 типа на изводи: основен, двојазичен и интернационален. Доколку се најавувате со ЕИД, имате можност да поднесете барање за електронски извод.",

    request_marriage_certificate: "Извод од матична книга на венчани",
    request_marriage_certificate_full:
      "Барање за извод од матичната книга на венчаните",
    request_marriage_certificate_description:
      "Поднесете електронско барање за издавање на извод од матичната книга на венчаните. На располагање ви се 3 типа на изводи: основен, двојазичен и интернационален. Доколку се најавувате со ЕИД, имате можност да поднесете барање за електронски извод.",

    request_death_certificate: "Извод од матична книга на умрени",
    request_death_certificate_description:
      "Поднесете електронско барање за извадање на извод од матичната книга на умрените.",

    request_marital_status_certificate: "Уверение за слободна брачна состојба",
    request_marital_status_certificate_description:
      "Поднесете електронско барање за издавање на уверение за слободна брачна состојба.",

    login: "Најава",
    login_with_eid: "Најава со еИД (uslugi.gov.mk)",
    login_with_eid_description:
      "Најавата со еИД ви овозможува да поднесете барање за електронски извод",
    log_in: "Најави се",
    registration: "Регистрација",
    sign_up: "Регистрирај се",
    language: "Јазик",
    submit_request: "Поднеси барање",

    bad_credentials: "Погрешно корисничко име или лозинка",
    certificateExpiredOrRevoked: "Сертификатот е истечен или блокиран",
    invalidCertificate: "Погрешен сертификат",
    userNotEnabled:
      "Корисникот сеуште не е одобрен од страна на операторите на УВМК",
    connection_error: "Проблем со конекција, обидете се подоцна",
    "User is legal entity":
      "Се работи за правно лице, ве молиме користете ја најавата за правни лица",
    "user is not an organization":
      "Внесениот корисник не е регистриран како правно лице",
    invalid_email_format: "Невалиден формат на е-меил адреса",
    invalid_embg_format: "Невалиден формат на ЕМБГ",
    changes_saved_success: "Промените се успешно зачувани",
    please_wait: "Ве молиме почекајте",
    required_field: " е задолжително поле",
    username: "Корисничко име",
    password: "Лозинка",
    firstName: "Име",
    lastName: "Презиме",
    gender: "Пол",
    embg: "ЕМБГ",
    country_living: "Држава на живеење",
    country: "Држава",
    country_born: "Држава на раѓање",
    place_living: "Место на живеење",
    place: "Место",
    place_born: "Место на раѓање",
    address_living: "Адреса на живеење",
    email: "Е-пошта",
    contact_phone: "Контакт телефон",
    confirm_password: "Потврди лозинка",
    choose_country: "Изберете држава",
    male: "Машки",
    female: "Женски",
    noOptionsText: "Не се пронајдени резултати",
    logout: "Одјави се",
    myProfile: "Мој профил",
    reportProblem: "Пријави проблем",
    addressLiving: "Адреса на живеење",
    address: "Адреса",
    phoneNumber: "Контакт телефон",
    save: "Зачувај",
    user_not_enabled:
      "Корисникот не е активен. Активирајте го вашиот кориснички профил со клик на линкот на е-меил пораката испратена на вашата адреса.",
    "email address exist": "Меил адресата е зафатена од друг корисник",
    "embg already exist": "Веќе постои корисник со проследениот ЕМБГ",
    password_changed_successfully: "Успешно променета лозинка",
    oldPassword: "Стара лозинка",
    newPassword: "Нова лозинка",
    confirmPassword: "Потврди нова лозинка",
    passwordChange: "Промена на лозинка",
    certificateChange: "Промена на сертификат",
    "passwords do not match": "Лозинките не се совпаѓаат",
    "old password is incorrect": "Погрешна лозинка",
    home: "Почетна",
    orders: "Нарачки",
    myOrders: "Нарачки",
    requestType: "Тип на барање",
    certificateType: "Тип на извод",
    requestDate: "Датум на поднесување",
    paymentStatus: "Статус на плаќање",
    downloadURL: "Линк за преземање",
    preview: "Преглед",
    UNPAID: "Неплатено",
    PAID: "Платено",
    PAYMENT_STATUS_FAILURE: "Неуспешно",
    PAYMENT_STATUS_IN_PROCESS: "Се процесира",
    PAYMENT_STATUS_CANCELLED: "Откажано",
    REQUEST_MARRIAGE_CERTIFICATE: "Извод - Венчани",
    REQUEST_BIRTH_CERTIFICATE: "Извод - Родени",
    REQUEST_DEATH_CERTIFICATE: "Извод - Умрени",
    REQUEST_BIRTH_TRANSCRIPTS: "Слободна брачна состојба",
    PICKUP_WAY_ELECTRONIC_CERT: "Електронски",
    PICKUP_WAY_UVMK: "Во просториите на УВМК",
    PICKUP_WAY_POSTOFFICE: "Пошта",
    open: "Отвори",
    urlDownloadDisabledDescription:
      "Линкот за преземање е достапен само за електронските изводи",
    no_records_found: "Нема пронајдени записи",
    data_not_found: "Податоците не се пронајдени",
    "user does not have privileges for this request":
      "Немате привилегии за пристап",
    forbidden: "Немате привилегии за пристап",
    "not found": "Податоците не се пронајдени",
    "you cannot change paid request":
      "Барањето е веќе платено и не може да се измени",
    paymentFailed: "Неуспешно",
    paymentCanceled: "Откажано",
    requestPreview: "ПРЕГЛЕД НА БАРАЊЕТО",
    back: "Назад",
    iAgree: "Се согласувам",
    payment_terms_agreement_text:
      "За да продолжите со плаќањето морате да се согласите со условите за плаќање. Доколку се согласите, ќе направите плаќање на нарачка за:",
    cert_type: "Тип на извод",
    groom_data: "Податоци за младоженецот",
    bride_data: "Податоци за невестата",
    marriage_data: "Податоци за бракот",
    typeRequestRequired:
      "Задолжително внесете тип на извод (основен, интернационален или двојазичен) во зависност од вашите потреби",
    birthDate: "Датум на раѓање",
    marriageDate: "Датум на склучување на бракот",
    marriageCountry: "Држава на склучување на бракот",
    marriagePlace: "Место на склучување на бракот ",
    bride_embg_required: "ЕМБГ е задолжително поле",
    at_least_2_chars_required: "Полето мора да содржи минимум 2 карактери",
    invalid_female_embg: "ЕМБГ не соодветстува со граѓанин од женски пол",
    invalid_male_embg: "ЕМБГ не соодветствува со граѓанин од машки пол",
    marriagePlaceManual: "Место на склучување на бракот",
    req_marr_cert: "Барање за издавање извод од матичната книга на венчаните",
    bridePlaceBirth: "Место на раѓање на невестата",
    groomPlaceBirth: "Место на раѓање на младоженецот",
    country_groom: "Држава на раѓање на младоженецот",
    country_bride: "Држава на раѓање на невестата",
    groomBirthDate: "Датум на раѓање на младоженецот",
    brideBirthDate: "Датум на раѓање на невестата",
    groomLastName: "Презиме на младоженецот",
    groomFirstName: "Име на младоженецот",
    brideLastName: "Презиме на невестата",
    brideFirstName: "Име на невестата",
    brideEMBG: "ЕМБГ на невестата",
    groomEMBG: "ЕМБГ на младоженецот",
    networkErrorMessage: "Проблем со конекција до серверите на УВМК",
    JDBCConnectonError: "Проблем со конекција до база на податоци",
    transaction_canceled: "Трансакцијата е откажана",
    transaction_error: "Трансакцијата е неуспешна",
    transaction_success: "Трансакцијата е успешна",
    request_marriage: "Закажување на термин за склучување на брак",
    request_marriage_description:
      "Електронско закажување на термин за склучување на брак. Оваа услуга е достапна само доколку сте најавени со ЕИД.",
    surnameMarriage: "Презиме по склучување на бракот",
    fatherFirstName: "Име на таткото",
    fatherLastName: "Презиме на таткото",
    motherFirstName: "Име на мајката",
    motherLastName: "Презиме на мајката",
    education: "Образование",
    schoolName: "Назив на завршено училиште/универзитет",
    documents: "Документи",
    citizenship: "Државјанство",
    myAppointments: "Термини",
    payment_price_request:
      "Вашето барање за склучување на брак надвор од просториите на УВМК е успешно поднесено. Операторите ќе го разгледаат барањето и ќе ја пресметаат цената за истото. Откако операторите ќе ја пресметаат цената, ќе ви стигне известување на вашата електронска пошта. Потоа, истото барање треба да го платите во рок од 24 часа. Доколку барањето не го платите во рок од 24 часа по пресметката, истото ќе биде откажано.",
    REGULAR_INSIDE: "Склучување на брак во просториите на УВМК",
    REGULAR_OUTSIDE: "Склучување на брак надвор од просториите на УВМК",
    requestDetails: "Податоци од барањето",
    NEW: "Ново",
    PRICE_REQUEST: "Барање за цена",
    PRICE_REQUEST_APPROVED: "Барањето е одобрено",
    COMPLETED: "Завршено",
    REJECTED: "Откажано",
    APPROVED: "Одобрено",
    SUBMITTED: "Поднесено",
    status: "Статус",
    motherMaidenLastname: "Родено презиме на мајката",
    fatherMaidenLastname: "Родено презиме на таткото",
    registration_of_newborn: "Пријава на новороденче",
    registration_of_newborn_description:
      "Оваа услуга е достапна само за Државјани на РМ кои имаат склучено брак во УВМК. Задолжително е двајцата родители да имаат ЕИД.",
    occupation: "Занимање",
    workingPosition: "Работна позиција",
    not_valid_format: "Невалиден формат",
    forgotten_password: "Заборавена лозинка",
    newborns: "Новороденчиња",
    intended_for: "Наменето за",
    type_of_certificate: "Тип на извод",
    no_mother_data: "Нема податоци за мајка",
    no_father_data: "Нема податоци за татко",
    field_must_have_min_2_characters: "Полето мора да има минимум 2 карактери",
    warning: "Напомена",
    death_cert_warning_1:
      "Барање за извод од матична клига на умрените може да се поднесе само за починати лица кои имаат единствен матичен број!",
    death_cert_warning_2:
      "Извод од матична книга на умрените може да се подигне само на шалтер и истиот може да го подигне лице од прв наследен ред или лице ополномоштено со полномошно од наследникот заверено на нотар!",
    death_cert_warning_3:
      "Барање може да се поднесе за починати лица кои се веќе пријавени за починати на шалтерите на УВМК!",
    death_cert_warning_4:
      "Доволно е да внесете само еден документ, односно документот кој ке го приложите како доказ кога ќе го подигнувате изводот.",
    applicant_identity_card: "Лична карта на подносител",
    applicant_passport: "Пасош на подносител",
    valid_from: "Важи од",
    valid_to: "Важи до",
    request_for_cert: "Барање за изведба на извод",
    born: "Родени",
    married: "Венчани",
    dead: "Умрени",
    single_marital_status: "Слободна брачна состојба",
    for: "за",
    with_EMBG: "со ЕМБГ",
    service: "Услуга",
    administrative_tax: "Административна такса",
    commission: "Провизија",
    total_amount: "Вкупен износ",
    no_data: "НЕМА ПОДАТОЦИ",
    pay: "Кон плаќање",
    lines_per_page: "Редови по страница",
    search: "Пребарај",
    martial_warning_1:
      "Овде треба да го прикачите скенираниот преведен пасош на партнерот со кој планирате да склучите брак",
    upload: "Прикачи",
    download: "Преземи",
    attach_file_here: "Прикачете ја датотеката тука",
    name_surname_newborn: "Име и презиме на новороденчето",
    father: "татко",
    application_newborns: "Пријави за новороденчиња",
    form_amount: "Износ на образец",
    postage: "Поштарина (до сите места во РМ)",
    cert_request_full_desc:
      "Извод од матична книга на родени (основен, интернационален, двојазичен)",
    cert_request_married_full_desc:
      "Извод од матична книга на венчани (основен, интернационален, двојазичен)",
    cert_request_dead_full_desc:
      "Извод од матична книга на умрени (основен, интернационален, двојазичен)",
    appointment_for_marriage_conclusion:
      "Закажување на термин за склучување на брак (во управата)",
    appointment_for_marriage_conclusion_outdoor:
      "Закажување на термин за склучување на брак (на терен)",
    newborn_registration_and_issuanse_of_birth_certificate:
      "Упис на новороденче и издавање на извод од матична книга на родени",
    denars: "ден.",
    searching: "Пребарување...",
    uvmk_address: "Адреса: бул. Св. Кирил и Методиј бр. 54, 1000 Скопје",
    uvmk_phone: "Телефон: 02 3223-787",
    uvmk_email: "Е-пошта: contact@uvmk.gov.mk",
    uvmk_all_rights_reserved: "Сите права се задржани",
    appointment_information: "Информации за терминот",
    appointment_warning:
      "Избраниот термин не е конечен, терминот ќе биде потврден од страна на матичарите.",
    appointment_type: "Тип на термин",
    habitant_place: "Населено место",
    select_appointment: "Изберете термин",
    location: "Локација",
    request_marriage_warning:
      "Овде треба да ги внесете податоците за личните карти на младоженецот и невестата и истите скенирани да ги прикачите во еден од следниве формати: .png .pdf .jpg .jpeg.",
    groom_identity_card: "Лична карта на младоженецот",
    date_of_issue: "Датум на издавање",
    expiry_date: "Датум на истекување",
    bride_identity_card: "Лична карта на невестата",
    premises: "Простории",
    marriage_date: "Датум на склучување на бракот",
    appointment: "Термин",
    spouses_information: "Податоци за сопружниците",
    the_bride: "Невестата",
    the_groom: "Младоженецот",
    education: "Школска спрема",
    name_school: "Назив на завршено училиште/универзитет",
    id_number: "Број на лична карта",
    id_card_issue_date: "Лична карта - датум на издавање",
    id_card_expiry_date: "Лична карта - датум на истекување",
    schedule_marriage_between:
      "Закажување на термин за склучување на брак помеѓу",
    newborn_information: "Податоци за новороденчето",
    newborn_first_name: "Име на новороденчето",
    mother_information: "Податоци за мајката",
    father_information: "Податоци за таткото",
    health_institution: "Здравствена установа",
    newborn_warning_1:
      "Доколку детето е родено во брачна заедница потребно е двајцата родители да ги потврдат податоците, во спротива доколку се работи за дете родено во вонбрачна заедница истото може да го пријави само мајката.",
    birth_status: "Статус на раѓање",
    in_marriage: "Во брак",
    out_of_wedlock: "Вонбрачно",
    birth_surname: "Родено презиме",
    nationality: "Националност",
    warning_1:
      "Овде треба да ги внесете податоците за личните карти на таткото и мајката и истите скенирани да ги прикачите во еден од следниве формати: .png .pdf .jpg .jpeg.",
    father_identity_card: "Лична карта на таткото",
    mother_identity_card: "Лична карта на мајката",
    newborn_surname: "Презиме на новороденчето",
    newborn_first_name_al: "Име на новороденчето (Албански)",
    newborn_surname_al: "Презиме на новороденчето (Албански)",
    birth_time: "Време на раѓање",
    method_of_cert_collection: "Начин на подигнување на изводот",
    parents_info_mother: "Податоци за родителите - мајка",
    school_university_name: "Име на училиште/универзитет",
    attached_documents: "Приложени документи",
    information_1:
      "Барањето е успешно зачувано, се чека на одобрување од брачниот другар, откако ќе биде одобрено може да продолжите кон плаќање.",
    optional_field: "Незадолжително поле",
    albanian: "(Албански)",
    id_card_issue_date: "Датум на издавање на лична карта",
    id_card_expiry_date: "Датум на истекување на лична карта",
    passport_issue_date: "Датум на издавање на пасош",
    passport_expiry_date: "Датум на истекување на пасош",
    passport_number: "Број на пасош",
    terms_of_use: "Услови за користење",

    generate_el_doc: "Генерирање електронски документ",
    electronic_doc_signed_success: "Електронскиот документ е успешно потпишан",
    electronic_doc_generated_success:
      "Документот е успешно генериран и чека на дигитален потпис. Ве молиме проверете ја точноста на податоците.",
    post_office: "Пошта",
    gen_doc_preview: "Преглед на генерираниот документ",
    upload_polnomosno:
      "Овде треба да прикачите полномошно од лицето за кое барате извод.",
    sign: "Потпиши",
    generating: "...",
    cancel: "Откажи",
    i_agree: "Се согласувам",
    legal_interest_number: "Број на предмет (Правен интерес)",
    login_legal_entities: "Најава за правни лица",
    load_certificate: "Вчитај сертификат",
    load_certificate_success: "Успешно вчитан сертификат",
    load_new_certificate: "Вчитај нов сертификат",
    certificate_change: "Промена на сертификат",
    type_of_user: "Тип на корисник",
    legal_entity: "Правно лице",
    natural_entity: "Физичко лице",
    password_complexity_validation_msg:
      "Мора да содржи минимум 8 карактери, барем една голема буква, барем една мала буква, една цифра и еден специјален знак",
    username_complexity_validation_msg:
      " (минимум 6 карактери, дозволени се мали и големи латинични букви, цифри и -._)",
    embg_format_validation_msg: "Мора да содржи точно 13 цифри",
    notar: "Нотар",
    sedishte: "Седиште",
    mesto_na_sedishte: "Место на седиште",
    ulica_na_sedishte: "Улица на седиште",
    naziv: "Назив",
    edb: "ЕДБ",
    proposer_data: "Податоци за подносителот",
    accept_terms: "Ги прифаќам условите за користење на порталот",
  },
  al: {
    generate_el_doc: "Gjenerimi i dokumentit elektronik",
    electronic_doc_signed_success:
      "Dokumenti elektronik është nënshkruar me sukses.",
    electronic_doc_generated_success:
      "Dokumenti është krijuar me sukses dhe është duke pritur për një nënshkrim dixhital. Ju lutemi kontrolloni saktësinë e të dhënave.",
    post_office: "Posta",
    gen_doc_preview: "Shikoni dokumentin e krijuar",
    upload_polnomosno:
      "Këtu ju duhet të bashkëngjitni një autorizim nga personi për të cilin po kërkoni një certifikatë.",
    sign: "Nënshkruaj",
    generating: "...",
    cancel: "Anulo",
    i_agree: "Jam dakord",
    login_legal_entities: "Identifikohu si person juridik",
    load_certificate: "Ngarko certifikatën",
    load_certificate_success: "Certifikata u ngarkua me sukses",
    load_new_certificate: "Ngarko një certifikatë të re",
    certificate_change: "Ndryshimi i certifikatës",
    type_of_user: "Lloji i përdoruesit",
    legal_entity: "Person juridik",
    natural_entity: "Person fizik",
    password_complexity_validation_msg:
      "Duhet të përmbajë të paktën 8 karaktere, të paktën një shkronjë të madhe, të paktën një shkronjë të vogël, një shifër dhe një karakter të veçantë",
    username_complexity_validation_msg:
      " (lejohen të paktën 6 karaktere, shkronja latine të vogla dhe të mëdha, numra dhe -._)",
    embg_format_validation_msg: "Duhet të përmbajë saktësisht 13 shifra",
    notar: "Noter",
    sedishte: "Selia",
    mesto_na_sedishte: "Vendi i selisë",
    ulica_na_sedishte: "Rruga e selisë",
    naziv: "Emri",
    edb: "EDB",
    proposer_data: "Të dhënat e paraqitësit",
    accept_terms: "Unë pranoj kushtet e përdorimit të portalit",

    "email already exist": "E-adresa tashmë është regjistruar",
    "you are not allowed to edit user data because the user is authorized with national eid":
      "Nuk lejohet të ndryshoni të dhënat e përdoruesit pasi që jeni të identifikuar me eID (eid.mk).",
    "electronic international birth certificate is not allowed yet":
      "Momentalisht, nuk është e mundur të keni certifikatën elektronike ndërkombëtare të lindjes nga regjistrat civil. Mund të zgjidhni marrjen e saj në zyrat ose nëpërmjet postës së ofrimit publik.",
    "electronic international death certificate is not allowed yet":
      "Momentalisht, nuk është e mundur të keni certifikatën elektronike ndërkombëtare të vdekjes nga regjistrat civil. Mund të zgjidhni marrjen e saj në zyrat ose nëpërmjet postës së ofrimit publik.",
    "electronic international marriage certificate is not allowed yet":
      "Momentalisht, nuk është e mundur të keni certifikatën elektronike ndërkombëtare të martesës nga regjistrat civil. Mund të zgjidhni marrjen e saj në zyrat ose nëpërmjet postës së ofrimit publik.",
    "place birth is required field": "Vendin e lindjes duhet ta plotësoni",
    "place birth manual is required field":
      "Vendin e lindjes duhet ta plotësoni",
    "place death is required field": "Vendin e vdekjes duhet ta plotësoni",
    "address death is required field": "Adresën e vdekjes duhet ta plotësoni",
    "death place manual is required field":
      "Vendin e vdekjes duhet ta plotësoni",
    "registry area is required field":
      "Zona e regjistrimit është fushë e detyrueshme për plotësim",
    "delivery registry area is required field":
      "Zona e regjistrimit ku do të merrni certifikatën është fushë e detyrueshme për plotësim",
    "pickup way cert must be UVMK":
      "Mënyra e marrjes së certifikatës duhet të jetë në zyrën e DULA-së",
    "passport or id number is required":
      "Kartë identiteti ose numër pasaporte është fushë e detyrueshme",
    "invalid passport issuing date":
      "Data e dhënies së pasaportës është e pasaktë",
    "invalid passport expiry date":
      "Data e skadimit të pasaportës është e pasaktë",
    "invalid id issuing date": "Data e dhënjes se letërnjoftimit është gabim",
    "invalid id expiry date": "Data e skadimit të letërnjoftimit është gabim",
    "placeLocation is required": "Vendndodhja është fushë e detyrueshme",
    "placeLivingManual is required": "Vendbanimi është fushë e detyrueshme",
    "certificate is required": "Certifikata është fushë e detyrueshme",
    "error while reading certificate": "Gabim gjatë leximit të certifikatës",
    "delivery registry area is not bilingual":
      "Zona e regjistrimit të dorëzimit nuk është e dygjuhëshme",
    "certificate already exist":
      "Certifikata që keni futur është tashmë e regjistruar",
    "registry area is not bilingual":
      "Zona e regjistrimit ku është bërë regjistrimi nuk është e përshtatshme për të lëshuar certifikatë dygjuhëshme",
    "person died in the current year and not found in our system":
      "Persona nuk është regjistruar si i vdekur në sistemin tonë, duke u vdekur në këtë vit",
    "request death certificate not found":
      "Kërkesa për certifikatën e vdekjes nuk është gjetur",
    "you are not authorized to change this request":
      "Ju nuk keni autoritet për të ndryshuar këtë kërkesë",
    "you cannot change paid request":
      "Ju nuk mund të ndryshoni një kërkesë që është tashmë paguar",
    "new certificate cannot be same old":
      "Certifikata e re nuk mund të jetë e njëjtë me atë të vjetrën",
    "certificate is required field": "Certifikata është fushë e detyrueshme",
    dateDeath: "Data e vdekjes",
    number: "Numër ",
    processing: "Duke u përpunuar",
    purposer_info: "Të dhënat për pjesëmarrësin",
    verifying_email: "Verifikimi i email-it tuaj",
    forbidden_h2: "Ridrejtim në faqen e hyrjes për ",
    "embg is not valid": "EMBG jo e vlefshme",
    email_verified: "Email-i është verifikuar",
    "password changed": "Fjalëkalimi është ndryshuar me sukses",
    "certificate changed": "Certifikata është ndryshuar me sukses",
    "token not valid":
      "Token-i nuk është i vlefshëm për ndryshimin e fjalëkalimit",
    "token expired or used":
      "Linku për ndryshimin e fjalëkalimit ka skaduar ose i perdorur",
    payment_policy:
      "Ju lutemi lexoni me kujdes KUSHTET E SHËRBIMIT PËR PAGIMIN ELEKTRONIK NË LLOGARI ME KARTA TË PAGUESHME PËRMES PORTALIT. Nëse pajtoheni me kushtet, zgjidhni butonin 'JAM DAKORD', pastaj do t'ju hapet një faqe nga Banka NLB Tutunska në të cilën do t'ju duhet të shënoni të dhënat e kartës tuaj të pagimit për të kryer pagesën.",
    BASIC: "CERTIFIKATË THEMELORE",
    INTERNATIONAL: "CERTIFIKATË NDËRKOMBËTAR",
    MULTILINGUAL: "CERTIFIKATË DYGJUHËSHE",
    priceList: "ÇMIMORE",
    institution: "INSTITUCION",
    institutionType: "LLOJI I INSTITUCIONIT",
    title: "EMËR",
    edb: "NUKJ",
    embg_doesnt_match: "EMBG nuk përputhet me përdoruesin e deklaruar",
    is_adult_message: "Personi është i rritur",
    pickup_way_reg_area: "Zyra rajonale ku do të merret dokumenti",
    delivery_address: "Adresa e dorëzimit të çertifikatës",
    delivery_place: "Vendi i dorëzimit të çertifikatës",
    mother_name: "Emri i nënës",
    mother_surname: "Mbiemri i nënës",
    mother_embg: "EMBG i nënës",
    father_embg: "EMBG i babait",
    father_name: "Emri i babait",
    father_surname: "Mbiemri i babait",
    purpose_and_type: "Qëllimi dhe lloji i dokumentit",
    basic_info: "Informacione themelore",
    person_death_info: "Informacione nga personi i vdekur",
    death_info: "Informacione mbi vdekjen",
    deathDate: "Data e vdekjes",
    deathCountry: "Shteti i vdekjes",
    deathPlace: "Vendi i vdekjes",
    deathAddress: "Adresa e vdekjes",
    parents_info: "Informacione për prindërit",
    pickup_way: "Mënyra e marrjes së dokumentit",
    sign_request: "Nënshkruaj kërkesën",
    upload_power_of_attorney: "Ngarko pushtetin e plotë",
    pickup_way_uvmk: "Në zyrat e DULA-së (në sportel)",
    pickup_way_postoffice: "Nëpërmjet postes",
    pickup_way_el: "Certifikat elektronike",
    payment: "Pagesa",
    req_free_martial_status: "Kërkesë për lirë vendim martesor",
    req_birth_cert: "Kërkesë për certifikatë lindjeje",
    req_death_cert: "Kërkesë për certifikatë vdekjeje",
    personaly: "Personalisht për veten",
    child: "Fëmijë i vogël",
    regular_cert: "Certifikatë themelor",
    internationale_cert: "Certifikatë ndërkombëtar",
    multilingual_cert: "Certifikatë dygjuhëshe",
    next: "Pasues",
    prev: "Parashtro",
    doesnt_match: "Fjalëkalimet nuk përputhen",
    incorrect_format: "Vendosni një format të vlefshëm",

    uvmk: "Drejtoria për udhëheqjen e librave amzë",
    ministry_of_justice: "Ministria e Drejtësisë",

    request_birth_certificate: "Certifikatë e lindjes",
    request_birth_certificate_full: "Kërkes për certifikat te lindjes",
    request_birth_certificate_description:
      "Paraqitni një kërkesë elektronike për certifikatë lindjeje. Ju keni në dispozicion 3 lloje të certifikatave: themelore, dygjuhëshe dhe ndërkombëtare. Nëse jeni të regjistruar me identitetin elektronik (eID), keni mundësi të paraqisni një kërkesë për një certifikatë elektronike.",

    request_marriage_certificate: "Certifikatë e martesës",
    request_marriage_certificate_full: "Kërkesë për certifikatë martese",
    request_marriage_certificate_description:
      "Paraqitni një kërkesë elektronike për certifikatë martese. Ju keni në dispozicion 3 lloje të certifikatave: themelore, dygjuhëshe dhe ndërkombëtare. Nëse jeni të regjistruar me identitetin elektronik (eID), keni mundësi të paraqisni një kërkesë për një certifikatë elektronike.",
    request_death_certificate: "Certifikatë e vdekjes",
    request_death_certificate_description:
      "Paraqitni një kërkesë elektronike për të nxjerrë një certifikatë vdekjeje nga regjistri civil.",
    request_marital_status_certificate: "Certifikatë e statusit martesor",
    request_marital_status_certificate_description:
      "Paraqitni një kërkesë elektronike për të nxjerrë një certifikatë për statusin e lirë martesor.",

    login: "Hyrje",
    login_with_eid: "Identifikohu me EID (uslugi.gov.mk)",
    login_with_eid_description:
      "Identifikimi me EID ju lejon të parashtroni një kërkesë për certifikatë elektronike",
    log_in: "Identifikohu",
    registration: "Regjistrimi",
    sign_up: "Regjistrohu",
    language: "Gjuha",
    submit_request: "Dërgo kërkesën",
    bad_credentials: "Përdorues ose fjalëkalim i gabuar",
    certificateExpiredOrRevoked: "Certifikata ka skaduar ose është bllokuar",
    invalidCertificate: "Certifikatë e pavlefshme",
    userNotEnabled:
      "Përdoruesi ende nuk është miratuar nga operatorët e DULA-së",
    connection_error: "Problem me lidhjen, provoni më vonë",
    "User is legal entity":
      "Është në pyetje një subjekt juridik, ju lutemi përdorni hyrjen për subjekte juridike",
    "user is not an organization":
      "Përdoruesi i futur nuk është i regjistruar si subjekt juridik",
    invalid_email_format: "Format i pavlefshëm i adresës së postës elektronike",
    invalid_embg_format: "Format i pavlefshëm i EMBG-së",
    changes_saved_success: "Ndryshimet u ruajtën me sukses",
    please_wait: "Ju lutemi prisni",
    required_field: " është fushë e detyrueshme",
    username: "Emri i përdoruesit",
    password: "Fjalëkalimi",
    firstName: "Emri",
    lastName: "Mbiemri",
    gender: "Gjinia",
    embg: "Numri am",
    country_living: "Shteti i banimit",
    country: "Shteti",
    country_born: "Shteti i lindjes",
    place_living: "Vendi i banimit",
    place: "Vendi",
    place_born: "Vendi i lindjes",
    address_living: "Adresa e banimit",
    email: "Email",
    contact_phone: "Kontakt telefoni",
    confirm_password: "Konfirmoni fjalëkalimin",
    choose_country: "Zgjidhni vendin",
    male: "Mashkull",
    female: "Femër",
    noOptionsText: "Nuk u gjetën rezultate",
    logout: "Ç'aktivizoj",
    myProfile: "Profili im",
    reportProblem: "Paraqit problem",
    addressLiving: "Adresa e banimit",
    address: "Adresa",
    phoneNumber: "Kontakt telefoni",
    save: "Ruaj",
    user_not_enabled:
      "Përdoruesi nuk është aktiv. Aktivizoni llogarinë tuaj duke klikuar në linkun në emailin që u dërgua në adresën tuaj.",
    "email address exist":
      "Adresa e emailit është e zënë nga një përdorues tjetër",
    "embg already exist": "Ekziston një përdorues me EMBG e dhënë",
    password_changed_successfully: "Fjalëkalimi është ndryshuar me sukses",
    oldPassword: "Fjalëkalimi i vjetër",
    newPassword: "Fjalëkalimi i ri",
    confirmPassword: "Konfirmo fjalëkalimin e ri",
    passwordChange: "Ndryshimi i fjalëkalimit",
    certificateChange: "Ndryshimi i certifikatës",
    "passwords do not match": "Fjalëkalimet nuk përputhen",
    "old password is incorrect": "Fjalëkalimi i vjetër është i pasaktë",
    home: "Ballina",
    orders: "Porosi",
    myOrders: "Porositë",
    requestType: "Lloji i kërkesës",
    certificateType: "Lloji i certifikatës",
    requestDate: "Data e paraqitjes së kërkesës",
    paymentStatus: "Statusi i pagesës",
    downloadURL: "URL për shkarkim",
    preview: "Parapëlqim",
    UNPAID: "Pa paguar",
    PAID: "I paguar",
    PAYMENT_STATUS_FAILURE: "Dështoi pagesa",
    PAYMENT_STATUS_IN_PROCESS: "Në proces",
    PAYMENT_STATUS_CANCELLED: "Anuluar",
    REQUEST_MARRIAGE_CERTIFICATE: "Certifikatë martese",
    REQUEST_BIRTH_CERTIFICATE: "Certifikatë lindjeje",
    REQUEST_DEATH_CERTIFICATE: "Certifikatë vdekjeje",
    REQUEST_BIRTH_TRANSCRIPTS: "Status i lirë martesor",
    PICKUP_WAY_ELECTRONIC_CERT: "Elektronikisht",
    PICKUP_WAY_UVMK: "Në hapësirat e DULA-së",
    PICKUP_WAY_POSTOFFICE: "Posta",
    open: "Hap",
    urlDownloadDisabledDescription:
      "Lidhja për shkarkim është e disponueshme vetëm për certifikatat elektronike",
    no_records_found: "Nuk u gjetën regjistra",
    data_not_found: "Të dhënat nuk u gjetën",
    "user does not have privileges for this request":
      "Ju nuk keni privilegje për të hyrë",
    forbidden: "Ju nuk keni privilegje për të hyrë",
    "not found": "Të dhënat nuk u gjetën",
    "you cannot change paid request":
      "Kërkesa është tashmë paguar dhe nuk mund të ndryshohet",
    paymentFailed: "Dështoi pagesa",
    paymentCanceled: "Anulohet pagesa",
    requestPreview: "SHIKIM PARAPRAK NË KËRKESË",
    back: "Mbrapa",
    iAgree: "Jam dakord",
    payment_terms_agreement_text:
      "Për të vazhduar me pagesën duhet të pranoni kushtet e pagesës. Nëse pranoni, do të bëni një pagesë për një porosi për:",
    cert_type: "Lloji i certifikatës",
    groom_data: "Të dhënat e dhëndrit",
    bride_data: "Të dhënat e nuses",
    marriage_data: "Të dhënat e martesës",
    typeRequestRequired:
      "Është e nevojshme të jepni llojin e certifikatës (themelore, ndërkombëtare ose dygjuhëshe) sipas nevojave tuaja",
    birthDate: "Data e lindjes",
    marriageDate: "Data e kurorëzimit",
    marriageCountry: "Vendi i kurorëzimit",
    marriagePlace: "Vendndodhja e martesës (qarku i regjistrimit civil)",
    bride_embg_required: "Numri am ësht i domosdoshëm",
    at_least_2_chars_required: "Fusha duhet të përmbajë të paktën 2 karaktere",
    invalid_female_embg:
      "Numri am nuk përputhet me një qytetare të gjinis femërore",
    invalid_male_embg: "Numri am nuk përputhet me një qytetar mashkull",
    marriagePlaceManual: "Vendndodhja e martesës",
    req_marr_cert: "Kërkesë për certifikatë martese",
    bridePlaceBirth: "Vendlindja e nuses",
    groomPlaceBirth: "Vendlindja e dhëndrit",
    country_groom: "Shteti i lindjes së dhëndrit",
    country_bride: "Shteti i lindjes së nuses",
    groomBirthDate: "Data e lindjes së dhëndrit",
    brideBirthDate: "Data e lindjes së nuse",
    groomLastName: "Mbiemri i dhëndrit",
    groomFirstName: "Emri i dhëndrit",
    brideLastName: "Mbiemri i nusës",
    brideFirstName: "Emri i nusës",
    brideEMBG: "Numri am i nuses",
    groomEMBG: "Numri am i dhëndrit",
    networkErrorMessage: "Problem me lidhjen me serverët e DULA-së",
    JDBCConnectonError: "Problem me lidhjen me bazën e të dhënave",
    transaction_canceled: "Transaksioni i anuluar",
    transaction_error: "Transaksioni dështoi",
    transaction_success: "Transaksioni u krye me sukses",
    request_marriage: "Planifikimi i datës për martesë",
    request_marriage_description:
      "Regjistrimi elektronik i një termini për martesë. Ky shërbim është i disponueshëm vetëm nëse jeni të regjistruar me ID-në tuaj elektronike.",
    surnameMarriage: "Mbiemër pas martesës",
    fatherFirstName: "Emri i babait",
    fatherLastName: "Mbiemri i babait",
    motherFirstName: "Emri i nënës",
    motherLastName: "Mbiemri i nënës",
    education: "Arsimimi",
    schoolName: "Emri i shkollës/universitetit të përfunduar",
    documents: "Dokumentet",
    citizenship: "Nënshtetsija",
    myAppointments: "Terminet",
    payment_price_request:
      "Kërkesa juaj për martesë jashtë hapësirave të DULA është paraqitur me sukses. Operatorët do të shqyrtojnë kërkesën dhe do ta llogarisin çmimin e saj. Pas llogaritjes së çmimit nga operatorët, do të merrni një njoftim në postën tuaj elektronike. Më pas, kërkesën duhet ta paguani brenda 24 orëve. Nëse nuk e paguani kërkesën brenda 24 orëve nga llogaritja, ajo do të anulohet.",
    REGULAR_INSIDE: "Martesa në hapësirat e DULA",
    REGULAR_OUTSIDE: "Martesa jashtë hapësirave të DULA",
    requestDetails: "Detajet e kërkesës",
    NEW: "E re",
    PRICE_REQUEST: "Kërkesë për çmim",
    PRICE_REQUEST_APPROVED: "Kërkesa është aprovuar",
    COMPLETED: "E përfunduar",
    REJECTED: "E refuzuar",
    APPROVED: "E aprovuar",
    SUBMITTED: "Dorëzuar",
    status: "Statusi",
    motherMaidenLastname: "Mbiemri am i nënës ",
    fatherMaidenLastname: "Mbiemri am i babait",
    registration_of_newborn: "Paraqitja e foshnjës të posalindur",
    registration_of_newborn_description:
      "Ky shërbim është i disponueshëm vetëm për shtetasit e RMV-së që janë të kurorëzuar në zyrat e DULA. Është e nevojshme që të dy prindërit të kenë ID-në e tyre elektronike.",
    occupation: "Profesioni",
    workingPosition: "Pozita e punës",
    not_valid_format: "Formati i pavlefshëm",
    forgotten_password: "Fjalëkalimi i harruar",
    newborns: "Të posalindur",
    intended_for: "Me qellim qe",
    no_mother_data: "Nuk ka të dhëna për nënën",
    no_father_data: "Nuk ka të dhëna për babain",
    field_must_have_min_2_characters:
      "Fusha duhet të ketë të paktën 2 karaktere",
    warning: "Paralajmërim",
    death_cert_warning_1:
      "Kërkesa për certifikatë vdekjeje mund të bëhet vetëm për personat e vdekur që kanë një numër unik identifikimi!",
    death_cert_warning_2:
      "Një ekstrakt nga regjistri i të vdekurve mund të merret vetëm në sportel dhe mund të merret nga një person i radhës së parë të trashëgimisë ose një person i autorizuar me prokurë nga trashëgimtari i vërtetuar në noter!",
    death_cert_warning_3:
      "Kërkesa mund të bëhet për personat e vdekur të cilët tashmë janë regjistruar si të vdekur në sportelet e DULA-së!",
    death_cert_warning_4:
      "Kërkesa mund të bëhet për personat e vdekur të cilët tashmë janë regjistruar si të vdekur në sportelet e DULA-së!",
    applicant_identity_card: "Letërnjoftimi i aplikuesit",
    applicant_passport: "Pasaporti i aplikuesit",
    valid_from: "Vlen nga",
    registry_area_of_birth_record: "Zyra ku është bërë regjistrimi në amzë",
    valid_to: "Vlen deri më",
    request_for_cert: "Kërkes për dhënje të certifikatës",
    born: "I lindur",
    married: "I martuar",
    dead: "I vdekur",
    single_marital_status: "Certifikatë e statusit martesor",
    for: "për",
    with_EMBG: "me EMBG",
    service: "Shërbimi",
    administrative_tax: "Taksa administrative",
    commission: "Provizion",
    total_amount: "Shuma totale",
    no_data: "NUK KA TË DHËNA",
    pay: "Paguaj",
    lines_per_page: "Rreshta për faqe",
    search: "Kërko",
    embg: "EMBG",
    martial_warning_1:
      "Këtu duhet të bashkangjitni pasaportin e skenuar dhe te perkthyer nga personi me të cilin planifikoni te martoheni",
    upload: "Bashkangjit",
    download: "Shkarko",
    attach_file_here: "Bashkangjit folderin këtu",
    name_surname_newborn: "Emri dhe mbiemri i foshnjes te posalindur",
    father: "Baba",
    application_newborns:
      "Aplikacione per paraqititjen e foshnjave te posalindur",
    form_amount: "Shuma e aplikacionit",
    postage: "Posta (në të gjitha vendet në RMV)",
    cert_request_full_desc:
      "Certifikatë e lindjes (themelore, ndërkombëtare, dygjuhëshe)",
    cert_request_married_full_desc:
      "Certifikatë e martesës (themelore, ndërkombëtare, dygjuhëshe)",
    cert_request_dead_full_desc:
      "Certifikatë e vdekjes (themelore, ndërkombëtare, dygjuhëshe)",
    appointment_for_marriage_conclusion:
      "Caktimi i terminit per lidhje martesore (ne administrat)",
    appointment_for_marriage_conclusion_outdoor:
      "Caktimi i terminit per lidhje martesore (ne teren)",
    newborn_registration_and_issuanse_of_birth_certificate:
      "Regjistrimi foshnjes të posalindur dhe dhenja e certifikatës së lindjes",
    denars: "denarë",
    searching: "Kërkim...",
    uvmk_address: "Adresa: Bul. Shën Kirili dhe Metodi 54 1000 Shkup",
    uvmk_phone: "Numri i Telefonit: 02 3223-787",
    uvmk_email: "Email: contact@uvmk.gov.mk",
    uvmk_all_rights_reserved: "Gjith te drejtat jan te garantuara",
    appointment_information: "Informata reht terminit",
    appointment_warning:
      "Termini i zgjedhur nuk është përfundimtar, termini do te jetë i konfirmuar nga ana e ofiqarit.",
    appointment_type: "Lloji i terminit",
    habitant_place: "Vendi i banuar",
    select_appointment: "Zgjedhni termin",
    location: "Lokacioni",
    request_marriage_warning:
      "Këtu duhet ti vendosni të dhënat e leternjoftimit nga dhëndri dhe nusja dhe te njejtat të skenuara ti bashkangjitni ne formatet ne vijim:png .pdf .jpg .jpeg.",
    groom_identity_card: "Leternjoftimi i dhëndrit",
    date_of_issue: "Data e lëshimit",
    expiry_date: "Data e skadimit",
    bride_identity_card: "Leternjoftimi i nuses",
    premises: "Lokacioni",
    marriage_date: "Data e regjistrimit së martesës",
    appointment: "Termin",
    spouses_information: "Te dhëna për bashkëshortët",
    the_bride: "Nusaja",
    the_groom: "Dhëndri",
    education: "Arsimi",
    name_school: "Emri i shkollës/universitetit të diplomuar",
    id_number: "Numri i letërnjoftimit",
    id_card_issue_date: "Kartën e identitetit - Data e lëshimit",
    id_card_expiry_date: "Kartën e identitetit - Data e skadimit",
    schedule_marriage_between: "Caktimi i terminit per bashkëshortësi ndërmjet",
    newborn_information: "Të dhëna për foshnjën të posalindur",
    newborn_first_name: "Emri i foshnjës të posalindur",
    mother_information: "Te dhëna për nënën",
    father_information: "Te dhëna për babin",
    health_institution: "Institucion shëndetësor",
    newborn_warning_1:
      "Nëse fëmija ka lindur në bashkësi martesore, është e nevojshme që të dy prindërit të konfirmojnë të dhënat, përkundrazi, nëse është fëmijë i lindur jashtë martese, të njëjtën mund ta raportojë vetëm nëna.",
    birth_status: "Statusi i lindjes",
    in_marriage: "I martuar",
    out_of_wedlock: "Jashtë martesës",
    birth_surname: "Mbiemri i lindjes",
    nationality: "Kombësia",
    warning_1:
      "Këtu duhet të futni të dhënat në kartën e identitetit të babait dhe nënës dhe t'i bashkëngjitni ato të skanuara në një nga formatet e mëposhtme: .png .pdf .jpg .jpeg.",
    father_identity_card: "Letënjoftimi i babait",
    mother_identity_card: "Letënjoftimi i nënës",
    newborn_surname: "Mbiemri i foshnjës të posalindur",
    newborn_first_name_al: "Emri i foshnjës të posalindur (Shqip)",
    newborn_surname_al: "Mbiemri i foshnjës të posalindur (Shqip)",
    birth_time: "Koha e lindej",
    method_of_cert_collection: "Mënyra e dhënjes të qertifikatës",
    parents_info_mother: "Të dhënat e prindërve",
    school_university_name: "Emri i shkollës/univerzitetit",
    attached_documents: "Dokumentet e bashkangjitura",
    information_1:
      "Kërkesa është ruajtur me sukses, është në pritje të miratimit nga bashkëshorti, pasi të miratohet mund të vazhdoni me pagesën.",
    optional_field: "Fushë opsionale",
    albanian: "(Shqip)",
    id_card_issue_date: "Data e lëshimit te letërnjoftimit",
    id_card_expiry_date: "Data e skadimit të leternjoftimit",
    passport_issue_date: "Data e lëshimit të pasaportës",
    passport_expiry_date: "Data e skadimit të pasaportës",
    passport_number: "Numri i pasaportës",
    terms_of_use: "Kushtet e përdorimit",
    type_of_certificate: "Ljoji i qertifikatës",
  },
});

if (localStorage.getItem("locale")) {
  LOCALE.setLanguage(localStorage.getItem("locale"));
} else {
  window.localStorage.setItem("locale", "mk");
  LOCALE.setLanguage("mk");
}

export const changeLanguage = (languageCode) => {
  var lng = window.localStorage.getItem("locale");
  if (typeof languageCode == "string" && (!lng || lng != languageCode)) {
    window.localStorage.setItem("locale", languageCode);
    window.location.reload();
  }
};
