import React, { useEffect } from "react";
import MomentUtils from "@date-io/moment";
import { moment } from "../../App";
import {
  Grid,
  Typography,
  Container,
  Grow,
  Paper,
  LinearProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Divider,
  Hidden,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import "moment/locale/mk"; // without this line it didn't work
import "moment/locale/sq"; // without this line it didn't work
import { history } from "../..";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { RequestBirthCertificateRepository } from "../../repositories/RequestBirthCertificateRepository";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { CountryRepository } from "../../repositories/CountryRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { Transliteration } from "../../common/helpers/Transliteration";
import { AllFormDataValidator } from "./RequestSingleMartialStatusValidator";
import { notifyShowErrorMessage } from "../../common/actions/CommonActions";
import PaymentComponent from "../payment/PaymentComponent";
import { RequestDeathCertificateRepository } from "../../repositories/RequestDeathCertificateRepository";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { SETTINGS } from "../../properties/ApiProps";
import { RequestSingleMartialStatusRepository } from "../../repositories/RequestSingleMartialStatusRepository";

const useStyles = makeStyles(styles);

export default function RequestSingleMartialStatusEdit() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [globalFormError, setGlobalFormError] = React.useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState();
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [deathCountrySearch, setDeathCountrySearch] = React.useState("");
  const [places, setPlaces] = React.useState([]);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [deliveryPlaceSearch, setDeliveryPlaceSearch] = React.useState("");
  const [mode, setMode] = React.useState("EDIT");

  const [formData, setFormData] = React.useState({
    embg: "",
    firstName: "",
    lastName: "",
    country: {
      countryCode: "807",
      id: "0",
      name: "СЕВЕРНА МАКЕДОНИЈА",
      nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      nameEn: "REPUBLIC OF NORTH MACEDONIA",
      printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
      printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      printNameEn: "REPUBLIC OF NORTH MACEDONIA",
    },
    placeBirth: { name: "" },
    placeBirthManual: "",
    registryArea: {},
    fatherName: "",
    fatherSurname: "",
    motherName: "",
    motherSurname: "",
    pickupWayCert: "PICKUP_WAY_UVMK",
    deliveryRegistryArea: "",
    passportDocumentNumber: "",
    passportExpiryDate: null,
    passportIssuingDate: null,
    idDocumentNumber: "",
    idExpiryDate: null,
    idIssuingDate: null,
  });
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");

  React.useEffect(() => {
    if (AuthService.getCurrentUser() == null) {
      setRedirectTo("/login");
    } else {
      loadFormData(id);
    }
  }, []);

  React.useEffect(() => {}, [formDataErrors]);

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  useEffect(() => {
    if (deathCountrySearch?.length > 1) {
      getCountries(deathCountrySearch);
    }
  }, [deathCountrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  useEffect(() => {
    if (deliveryPlaceSearch?.length > 1) {
      getPlaces(deliveryPlaceSearch);
    }
  }, [deliveryPlaceSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const handleChange = (name, value) => {
    let tmp = { ...formData };
    if (name === "country") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "placeBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "embg") {
      tmp[name] = value;
      setFormData(tmp);
    } else if (name === "registryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.id === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryRegistryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.title === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
  };

  const handleChangeDate = (date, name) => {
    let data = { ...formData };
    if (date) {
      data[name] = moment(date.set({ hour: 0, minute: 0, second: 0 }));
    } else {
      data[name] = null;
    }
    setFormData(data);
  };

  React.useEffect(() => {
    UsersRepository.fetchCurrentUser().then(
      (res) => {
        setCurrentUser(res.data);
      },
      (err) => {
        setRedirectTo("/login");
      }
    );
    getActiveRegistryAreas();
  }, []);

  const loadFormData = (id) => {
    setLoading(true);
    RequestSingleMartialStatusRepository.get(id)
      .then((res) => {
        let data = { ...res.data };
        data.deliveryPlace = data.deliveryPlace
          ? data.deliveryPlace
          : { name: "" };
        if (res.data.requestSingleMartialStatusDocuments.length > 0) {
          res.data.requestSingleMartialStatusDocuments.forEach((doc) => {
            if (
              doc.appDocType.codename ===
              "application.documents.idcard.proposer"
            ) {
              data.idDocumentNumber = doc.documentNumber
                ? doc.documentNumber
                : "";
              if (doc.expiryDate) {
                data.idExpiryDate = moment(parseInt(doc.expiryDate));
              }
              if (doc.issuingDate) {
                data.idIssuingDate = moment(parseInt(doc.issuingDate));
              }
            }
            if (
              doc.appDocType.codename ===
              "application.documents.passport.proposer"
            ) {
              data.passportDocumentNumber = doc.documentNumber
                ? doc.documentNumber
                : "";
              if (doc.expiryDate) {
                data.passportExpiryDate = moment(parseInt(doc.expiryDate));
              }
              if (doc.issuingDate) {
                data.passportIssuingDate = moment(parseInt(doc.issuingDate));
              }
            }
          });
        }
        setPlaces([data.deliveryPlace]);
        setFormData(data);
        setLoading(false);
      })
      .catch((err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (!AllFormDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await AllFormDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
      setLoading(true);
      RequestSingleMartialStatusRepository.update(formData)
        .then((res) => {
          let data = { ...formData };
          data.id = res.data.id;
          setFormData(data);
          setLoading(false);
          setMode("PAYMENT");
          setGlobalFormError(null);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
          setGlobalFormError(ErrorMessageResolver.resolve(err));
          setLoading(false);
        });
    }
  };

  const backToEdit = () => {
    setMode("EDIT");
  };

  const initializePayment = () => {
    window.location.href = `${
      SETTINGS.API_URL
    }/payment/initialization?reasonType=transcript&rel=${
      formData.id
    }&access_token=${AuthService.getToken().access_token}`;
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Header />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {LOCALE.req_free_martial_status}
              </Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                {mode === "EDIT" && (
                  <Grid container spacing={3} style={{ padding: "20px" }}>
                    {globalFormError && (
                      <Grid item xs={12}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                    )}
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.embg}
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              size="small"
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange("embg", e.target.value)
                              }
                              value={formData?.embg}
                              error={formDataErrors.embg ? true : false}
                              helperText={
                                formDataErrors.embg ? formDataErrors.embg : ""
                              }
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.firstName}
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              size="small"
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "firstName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.firstName}
                              error={formDataErrors.firstName !== undefined}
                              helperText={formDataErrors.firstName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.lastName}
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              size="small"
                              fullWidth
                              value={formData?.lastName}
                              error={formDataErrors.lastName !== undefined}
                              helperText={formDataErrors.lastName}
                              onChange={(e) =>
                                handleChange(
                                  "lastName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              variant="outlined"
                            ></TextField>
                          </Grid>

                          <Grid item xs={12}>
                            <Autocomplete
                              noOptionsText={LOCALE.noOptionsText}
                              autoComplete={false}
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              size="small"
                              value={
                                formData.country?.name
                                  ? formData.country?.name
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                handleChange("country", newValue);
                              }}
                              inputValue={countrySearch}
                              onInputChange={(event, newInputValue) => {
                                setCountrySearch(
                                  Transliteration.toUpperCyrilic(newInputValue)
                                );
                              }}
                              options={countries?.map(
                                (country) => country.name
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={LOCALE.country_born}
                                  fullWidth
                                  error={formDataErrors.country !== undefined}
                                  helperText={formDataErrors.country}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          {formData.country.id === "0" ? (
                            <Grid item xs={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                size="small"
                                disabled={
                                  loading || formData?.paymentStatus === "PAID"
                                }
                                value={
                                  formData.placeBirth?.name
                                    ? formData.placeBirth?.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("placeBirth", newValue);
                                }}
                                inputValue={placeSearch}
                                onInputChange={(event, newInputValue) => {
                                  setPlaceSearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={places?.map((place) => place.name)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.place_born}
                                    fullWidth
                                    error={
                                      formDataErrors.placeBirth !== undefined
                                    }
                                    helperText={formDataErrors.placeBirth}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          ) : (
                            <React.Fragment>
                              <Grid item xs={12}>
                                <TextField
                                  label={LOCALE.place_born}
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  size="small"
                                  variant="outlined"
                                  autoComplete="false"
                                  fullWidth={true}
                                  error={
                                    formDataErrors.placeBirthManual !==
                                    undefined
                                  }
                                  helperText={formDataErrors.placeBirthManual}
                                  onChange={(e) => {
                                    handleChange(
                                      "placeBirthManual",
                                      Transliteration.toUpperCyrilic(
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={formData?.placeBirthManual}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  size="small"
                                  error={formDataErrors?.registryArea}
                                >
                                  <InputLabel>
                                    {
                                      "Матично подрачје во кое е направен уписот"
                                    }
                                  </InputLabel>
                                  <Select
                                    size="small"
                                    value={
                                      formData?.registryArea?.id
                                        ? formData?.registryArea?.id
                                        : {}
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        "registryArea",
                                        e.target.value
                                      )
                                    }
                                    label={
                                      "Матично подрачје во кое е направен уписот"
                                    }
                                  >
                                    {registryAreas &&
                                      registryAreas.map((regArea, index) => (
                                        <MenuItem
                                          key={index}
                                          value={regArea.id}
                                          size="small"
                                        >
                                          {regArea.title}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  {formDataErrors?.registryArea && (
                                    <FormHelperText error={true}>
                                      {formDataErrors.registryArea}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                                {formDataErrors?.registryArea && (
                                  <FormHelperText error={true}>
                                    {formDataErrors.registryArea}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </React.Fragment>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.mother_name}
                              size="small"
                              fullWidth
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "motherName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.motherName}
                              error={formDataErrors.motherName !== undefined}
                              helperText={formDataErrors.motherName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.mother_surname}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "motherSurname",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.motherSurname}
                              error={formDataErrors.motherSurname !== undefined}
                              helperText={formDataErrors.motherSurname}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.father_name}
                              size="small"
                              fullWidth
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "fatherName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.fatherName}
                              error={formDataErrors.fatherName !== undefined}
                              helperText={formDataErrors.fatherName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.father_surname}
                              size="small"
                              fullWidth
                              variant="outlined"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              onChange={(e) =>
                                handleChange(
                                  "fatherSurname",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.fatherSurname}
                              error={formDataErrors.fatherSurname !== undefined}
                              helperText={formDataErrors.fatherSurname}
                            ></TextField>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              size="small"
                            >
                              <InputLabel id="pickup_way-label">
                                {LOCALE.pickup_way}
                              </InputLabel>
                              <Select
                                labelId="pickup_way-outlined-label"
                                id="pickup_way-outlined"
                                value={formData.pickupWayCert}
                                onChange={(e) =>
                                  handleChange("pickupWayCert", e.target.value)
                                }
                                label={LOCALE.pickup_way}
                              >
                                <MenuItem value="PICKUP_WAY_UVMK">
                                  {LOCALE.pickup_way_uvmk}
                                </MenuItem>
                                {/* <MenuItem value="PICKUP_WAY_ELECTRONIC_CERT">
                                  {LOCALE.pickup_way_el}
                                </MenuItem> */}
                              </Select>
                            </FormControl>
                          </Grid>

                          {formData.pickupWayCert === "PICKUP_WAY_UVMK" && (
                            <Grid item xs={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                size="small"
                                disabled={
                                  loading || formData?.paymentStatus === "PAID"
                                }
                                value={
                                  formData?.deliveryRegistryArea?.title
                                    ? formData?.deliveryRegistryArea?.title
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange(
                                    "deliveryRegistryArea",
                                    newValue
                                  );
                                }}
                                inputValue={deliveryRegistryAreaSearch}
                                onInputChange={(event, newInputValue) => {
                                  setDeliveryRegistryAreaSearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={registryAreas?.map(
                                  (regArea) => regArea.title
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.pickup_way_reg_area}
                                    fullWidth
                                    error={formDataErrors.deliveryRegistryArea}
                                    helperText={
                                      formDataErrors.deliveryRegistryArea
                                    }
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.id_number}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange("idDocumentNumber", e.target.value)
                              }
                              value={formData.idDocumentNumber}
                              error={
                                formDataErrors.idDocumentNumber !== undefined
                              }
                              helperText={formDataErrors.idDocumentNumber}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={MomentUtils}
                              >
                                <KeyboardDatePicker
                                  disableToolbar
                                  format="DD/MM/YYYY"
                                  // margin="dense"
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  size="small"
                                  inputVariant="outlined"
                                  id="date-to"
                                  label={LOCALE.id_card_issue_date}
                                  value={formData.idIssuingDate}
                                  onChange={(date) =>
                                    handleChangeDate(date, "idIssuingDate")
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={MomentUtils}
                              >
                                <KeyboardDatePicker
                                  disableToolbar
                                  format="DD/MM/YYYY"
                                  // margin="dense"
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  size="small"
                                  inputVariant="outlined"
                                  id="date-to"
                                  label={LOCALE.id_card_expiry_date}
                                  value={formData.idExpiryDate}
                                  onChange={(date) =>
                                    handleChangeDate(date, "idExpiryDate")
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.passport_number}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "passportDocumentNumber",
                                  e.target.value
                                )
                              }
                              value={formData.passportDocumentNumber}
                              error={
                                formDataErrors.passportDocumentNumber !==
                                undefined
                              }
                              helperText={formDataErrors.passportDocumentNumber}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={MomentUtils}
                              >
                                <KeyboardDatePicker
                                  disableToolbar
                                  format="DD/MM/YYYY"
                                  // margin="dense"
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  size="small"
                                  inputVariant="outlined"
                                  id="date-to"
                                  label={LOCALE.passport_issue_date}
                                  value={formData.passportIssuingDate}
                                  onChange={(date) =>
                                    handleChangeDate(
                                      date,
                                      "passportIssuingDate"
                                    )
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={MomentUtils}
                              >
                                <KeyboardDatePicker
                                  disableToolbar
                                  format="DD/MM/YYYY"
                                  // margin="dense"
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  size="small"
                                  inputVariant="outlined"
                                  id="date-to"
                                  label={LOCALE.passport_expiry_date}
                                  value={formData.passportExpiryDate}
                                  onChange={(date) =>
                                    handleChangeDate(date, "passportExpiryDate")
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={3} style={{ padding: "20px" }}>
                  {mode === "PAYMENT" && (
                    <Grid item xs={12}>
                      <PaymentComponent reqSingleMartialStatus={formData} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {mode === "EDIT" && formData?.paymentStatus !== "PAID" && (
                      <Button
                        disabled={loading || formData?.paymentStatus === "PAID"}
                        color="secondary"
                        size="small"
                        variant="contained"
                        style={{ float: "right" }}
                        onClick={() => handleSubmit()}
                      >
                        {LOCALE.pay}
                      </Button>
                    )}
                    {mode === "EDIT" && (
                      <Button
                        disabled={loading}
                        color="default"
                        size="small"
                        variant="contained"
                        onClick={() => setRedirectTo("/orders")}
                      >
                        {LOCALE.back}
                      </Button>
                    )}
                    {mode === "PAYMENT" && (
                      <Button
                        disabled={loading}
                        color="default"
                        size="small"
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        onClick={() => backToEdit()}
                      >
                        {LOCALE.back}
                      </Button>
                    )}
                    {mode === "PAYMENT" && (
                      <Button
                        disabled={loading}
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{ marginTop: "10px", float: "right" }}
                        onClick={() => initializePayment()}
                      >
                        {LOCALE.iAgree}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}
