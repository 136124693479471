import React, { useEffect } from "react";
import MomentUtils from "@date-io/moment";
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Grow,
  Hidden,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Step,
  Icon,
  StepLabel,
  Stepper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { useDispatch } from "react-redux";
import { LOCALE } from "../../properties/Locale";
import Header from "../../common/Header";
import { Redirect } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import TelegramIcon from "@material-ui/icons/Telegram";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import clsx from "clsx";
import bgImg from "../../assets/img/productCurvyLines.png";
import LinkIcon from "@material-ui/icons/Link";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { CountryRepository } from "../../repositories/CountryRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { Alert, Autocomplete, AlertTitle } from "@material-ui/lab";
import { Transliteration } from "../../common/helpers/Transliteration";
import Footer from "../../common/Footer";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import { RequestBirthCertificateRepository } from "../../repositories/RequestBirthCertificateRepository";
import { moment } from "../../App";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import PaymentComponent from "../payment/PaymentComponent";
import {
  BrideDataValidator,
  GroomDataValidator,
  MarriageCertificatePickupDataValidator,
  MarriageDataValidator,
} from "./RequestMarriageCertificateValidator";
import { RequestMarriageCertificateRepository } from "../../repositories/RequestMarriageCertificateRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { SETTINGS } from "../../properties/ApiProps";
import { AuthService } from "../auth/AuthService";
import { NoteAdd } from "@material-ui/icons";
import { notifyShowErrorMessage } from "../../common/actions/CommonActions";
import Upload from "../../common/components/Upload";
import { FilesRepository } from "../../repositories/FilesRepository";

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export default function RequestMarriageCertificateComponent() {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [generatedDocumentURL, setGeneratedDocumentURL] = React.useState();
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [generatedDocumentFile, setGeneratedDocumentFile] = React.useState();
  const [printDialog, setPrintDialog] = React.useState(false);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [ecertProcessStatus, setEcertProcessStatus] =
    React.useState("NOT_GENERATED");
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [persistedRequest, setPersistedRequest] = React.useState();
  const [currentUser, setCurrentUser] = React.useState();
  const [powerOfAttorneyScan, setPowerOfAttorneyScan] = React.useState([]);

  function getSteps() {
    if (currentUser?.proposerType === "ORGANIZATION") {
      return [
        LOCALE.cert_type,
        LOCALE.bride_data,
        LOCALE.groom_data,
        LOCALE.marriage_data,
        LOCALE.pickup_way,
        LOCALE.sign_request,
        LOCALE.payment,
      ];
    } else {
      return [
        LOCALE.cert_type,
        LOCALE.bride_data,
        LOCALE.groom_data,
        LOCALE.marriage_data,
        LOCALE.pickup_way,
        LOCALE.payment,
      ];
    }
  }

  const steps = getSteps();

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    let icons = {};
    if (currentUser?.proposerType)
      if (currentUser?.proposerType === "ORGANIZATION") {
        icons = {
          1: <SettingsIcon />,
          2: <PermIdentityIcon />,
          3: <PermIdentityIcon />,
          4: <LinkIcon />,
          5: <TelegramIcon />,
          6: <NoteAdd />,
          7: <CreditCardIcon />,
        };
      } else {
        icons = {
          1: <SettingsIcon />,
          2: <PermIdentityIcon />,
          3: <PermIdentityIcon />,
          4: <LinkIcon />,
          5: <TelegramIcon />,
          6: <CreditCardIcon />,
        };
      }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  const defaultCountry = {
    countryCode: "807",
    id: "0",
    name: "СЕВЕРНА МАКЕДОНИЈА",
    nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
    nameEn: "REPUBLIC OF NORTH MACEDONIA",
    printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
    printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
    printNameEn: "REPUBLIC OF NORTH MACEDONIA",
  };

  const [formData, setFormData] = React.useState({
    certForChild: "false",
    typeRequest: "0",

    brideEmbg: "",
    brideFirstName: "",
    brideLastName: "",
    brideCountry: defaultCountry,
    bridePlaceBirth: { name: "" },
    bridePlaceBirthManual: "",
    brideBirthDate: null,

    groomEmbg: "",
    groomFirstName: "",
    groomLastName: "",
    groomCountry: defaultCountry,
    groomPlaceBirth: { name: "" },
    groomPlaceBirthManual: "",
    groomBirthDate: null,

    marriageDate: null,
    marriageCountry: defaultCountry,
    marriagePlace: { name: "" },
    marriagePlaceManual: "",

    pickUpWayCert: "PICKUP_WAY_UVMK",
    registryArea: {},
    deliveryRegistryArea: {},
    deliveryPlace: { name: "" },
    deliveryAddress: "",
  });
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");

  useEffect(() => {
    if (currentUser) {
      let data = formData;
      if (currentUser.proposerType !== "ORGANIZATION") {
        if (currentUser.gender === "MALE") {
          data.groomEmbg = currentUser.embg;
          data.groomFirstName = currentUser.firstName;
          data.groomLastName = currentUser.lastName;
          data.groomCountry = currentUser.countryLiving;
          if (currentUser.embg) {
            data.groomBirthDate = EmbgToDate.getDate(currentUser.embg);
          }
          if (currentUser?.birthPlace !== undefined)
            data.groomPlaceBirth = currentUser?.birthPlace;
        } else {
          data.brideEmbg = currentUser.embg;
          data.brideFirstName = currentUser.firstName;
          data.brideLastName = currentUser.lastName;
          data.brideCountry = currentUser.countryLiving;
          if (currentUser.embg) {
            data.brideBirthDate = EmbgToDate.getDate(currentUser.embg);
          }
          if (currentUser?.birthPlace !== undefined)
            data.bridePlaceBirth = currentUser?.birthPlace;
        }
        setFormData(data);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!AuthService.getCurrentUser()) {
      if (window.location.search.includes("eid=true")) {
        window.location.href = `https://eid.mk/issue/wsfed?wa=wsignin1.0&wtrealm=https://e-portal.uvmk.gov.mk/&wctx=redirectUrl=${window.location.pathname}&id=passive&ru=%2f&wreply=https://e-portal.uvmk.gov.mk/login_token_eid`;
      } else {
        setRedirectTo("/login");
        return;
      }
    } else {
      UsersRepository.fetchCurrentUser().then(
        (res) => {
          setCurrentUser(res.data);
        },
        (err) => {
          setRedirectTo("/login");
        }
      );
      getActiveRegistryAreas();
    }
  }, []);

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const onUploadComponentClose = (files) => {
    setPowerOfAttorneyScan(files);
  };

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  const handleChange = (name, value) => {
    let tmp = { ...formData };
    if (name === "marriageCountry") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "brideCountry") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "groomCountry") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "groomPlaceBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "bridePlaceBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "marriagePlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryPlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "brideEmbg") {
      if (value.length >= 7) {
        tmp.brideBirthDate = EmbgToDate.getDate(value);
      }
      tmp[name] = value;
      setFormData(tmp);
    } else if (name === "groomEmbg") {
      if (value.length >= 7) {
        tmp.groomBirthDate = EmbgToDate.getDate(value);
      }
      tmp[name] = value;
      setFormData(tmp);
    } else if (name === "deliveryRegistryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.title === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
  };

  const generateCertificate = (id) => {
    setGlobalFormError();
    setSaving(true);
    setPrintDialog(false);
    setGlobalFormError();
    axios({
      url: `${SETTINGS.API_URL}/requestMarriageCertificates/generate/${id}`,
      method: "POST",
      params: {
        access_token: AuthService.getToken().access_token,
      },
      responseType: "blob",
    })
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setEcertProcessStatus("GENERATED");
        setGeneratedDocumentFile(file);
        setGeneratedDocumentURL(fileURL);
        setPrintDialog(true);
        setSaving(false);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        console.log(err.message);
        setSaving(false);
        setGlobalFormError("Грешка при генерирање на документот");
      });
  };

  const handleNextStepLegalEntity = () => {
    if (activeStep === 0) {
      if (!validateTypeRequest()) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 1) {
      if (!validateBrideData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      if (!validateGroomData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 3) {
      if (!validateMarriageData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 4) {
      setLoading(true);
      if (!validatePickupData(formData)) return;
      RequestMarriageCertificateRepository.create(formData)
        .then((res) => {
          setPersistedRequest(res.data);
          setFormData(res.data);
          generateCertificate(res.data.id);
          setLoading(false);
          setGlobalFormError(null);
        })
        .catch((err) => {
          setGlobalFormError(ErrorMessageResolver.resolve(err));
          setLoading(false);
        });
    }
    if (activeStep === 5) {
      setLoading(true);
      if (
        currentUser.proposerType === "ORGANIZATION" &&
        !currentUser?.institution?.institutionType == 2
      ) {
        FilesRepository.uploadPowerOfAttorneyScan(
          powerOfAttorneyScan,
          persistedRequest.id,
          "MARRIAGES"
        ).then(
          (res) => {
            setLoading(false);
            setGlobalFormError(null);
            setActiveStep(activeStep + 1);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setGlobalFormError(ErrorMessageResolver.resolve(err));
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
        setActiveStep(activeStep + 1);
      }
    }
    setFormDataErrors({});
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (!validateTypeRequest()) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 1) {
      if (!validateBrideData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      if (!validateGroomData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 3) {
      if (!validateMarriageData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 4) {
      setLoading(true);
      if (!validatePickupData(formData)) return;
      RequestMarriageCertificateRepository.create(formData)
        .then((res) => {
          setPersistedRequest(res.data);
          setFormData(res.data);
          setActiveStep(activeStep + 1);
          setLoading(false);
          setGlobalFormError(null);
        })
        .catch((err) => {
          setGlobalFormError(ErrorMessageResolver.resolve(err));
          setLoading(false);
        });
    }
    setFormDataErrors({});
  };

  const handlePrevStep = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const validateTypeRequest = () => {
    if (!formData.typeRequest) setGlobalFormError(LOCALE.typeRequestRequired);
    else return true;
  };

  const validatePickupData = () => {
    if (!MarriageCertificatePickupDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await MarriageCertificatePickupDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  const validateBrideData = () => {
    if (!BrideDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await BrideDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          console.log(err);
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  const validateGroomData = () => {
    if (!GroomDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await GroomDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  const validateMarriageData = () => {
    if (!MarriageDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await MarriageDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  const downloadGeneratedDocument = () => {
    window.open(generatedDocumentURL, "_blank");
  };

  const signDocument = () => {
    if (typeof AuctaSignerApp === "function") {
      setPrintDialog(false);
      setLoading(true);
      console.log(window.ext);
      var reader = new FileReader();
      reader.readAsDataURL(generatedDocumentFile);
      reader.onloadend = function () {
        var base64data = reader.result.split(",")[1];
        // console.log(base64data);
        window.ext.signAndCertPDF(
          base64data,
          null,
          null,
          false,
          0,
          400,
          75,
          530,
          135,
          false,
          "null",
          (status, message) => {
            signAndCertCallback(status, message);
          }
        );
      };
    } else {
      setPrintDialog(false);
      setLoading(false);
      setGlobalFormError(
        "Ве молиме инсталирајте ги потребните алатки за дигитално потпишување"
      );
    }
  };

  const uploadSignedContent = (signedContent) => {
    FilesRepository.uploadSignedRequestCertificate(
      signedContent,
      persistedRequest.id,
      "MARRIAGES"
    )
      .then((res) => {
        if (res.data === true) {
          setGlobalFormError();
          setEcertProcessStatus("SIGNED_AND_UPLOADED");
          setPrintDialog(false);
          setLoading(false);
        } else {
          setEcertProcessStatus("GENERATED");
          setPrintDialog(false);
          setGlobalFormError(
            "Грешка при прикачување на дигитално потпишаниот документ"
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setEcertProcessStatus("GENERATED");
        setPrintDialog(false);
        setGlobalFormError(
          "Грешка при прикачување на дигитално потпишаниот документ"
        );
        setLoading(false);
      });
  };

  function signAndCertCallback(status, message) {
    if (status === false && message.status === "Error") {
      if (message.error === "No valid certificates.") {
        setGlobalFormError(
          "Не е избран валиден сертификат за дигитално потпишување"
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else if (
        message.error.includes("The action was cancelled by the user.")
      ) {
        setGlobalFormError("Потпишувањето беше откажано на ваше барање");
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else {
        setGlobalFormError(
          "Грешка при потпишување на електронскиот документ. Ве молиме пријавете го овој проблем кај администраторот."
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      }
    } else if (status === true) {
      console.log(status);
      console.log(message);
      fetch(message.blobURL).then((r) => {
        r.blob().then((blob) => {
          let file = new Blob([blob], { type: "application/pdf" });
          uploadSignedContent(file);
        });
      });
    }
  }

  const handleChangeDate = (date, name) => {
    let data = { ...formData };
    data[name] = date;
    setFormData(data);
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} style={{ marginBottom: "20px" }}>
              <Typography variant="h5">{LOCALE.req_marr_cert}</Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: "0" }}
                    display="flex"
                    alignItems="center"
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      backgroundColor: "rgb(212 212 212 / 0.85)",
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "20px",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      <strong>{steps[activeStep]}</strong>
                    </Typography>
                  </Grid>
                  {globalFormError && (
                    <React.Fragment>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                      <Grid item xs={12} md={6}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                    </React.Fragment>
                  )}
                  {activeStep === 0 && (
                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      <Grid container>
                        <Grid item md={2} xs={false}></Grid>
                        <Hidden smUp>
                          <Grid item xs={12}>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            ></Divider>
                          </Grid>
                        </Hidden>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          style={{ textAlign: "center" }}
                        >
                          <FormControl
                            component="fieldset"
                            style={{ paddingLeft: "20px" }}
                          >
                            <FormLabel
                              component="legend"
                              style={{ marginBottom: "15px" }}
                            ></FormLabel>
                            <RadioGroup
                              aria-label="typeRequest"
                              name="typeRequest"
                              value={formData.typeRequest}
                              onChange={(e) =>
                                handleChange("typeRequest", e.target.value)
                              }
                              size="small"
                            >
                              <FormControlLabel
                                value="0"
                                control={<Radio size="small" />}
                                label={LOCALE.regular_cert}
                              />
                              <FormControlLabel
                                value="1"
                                control={<Radio size="small" />}
                                label={LOCALE.internationale_cert}
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio size="small" />}
                                label={LOCALE.multilingual_cert}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 1 && (
                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      <Grid container>
                        <Hidden smUp>
                          <Grid item xs={12}>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            ></Divider>
                          </Grid>
                        </Hidden>
                        <Grid item xs={false} md={4}></Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ textAlign: "center" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <TextField
                                label={LOCALE.embg}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange("brideEmbg", e.target.value)
                                }
                                value={
                                  formData?.brideEmbg ? formData.brideEmbg : ""
                                }
                                error={formDataErrors.brideEmbg !== undefined}
                                helperText={formDataErrors.brideEmbg}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                label={LOCALE.firstName}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "brideFirstName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={
                                  formData?.brideFirstName
                                    ? formData.brideFirstName
                                    : ""
                                }
                                error={
                                  formDataErrors.brideFirstName !== undefined
                                }
                                helperText={formDataErrors.brideFirstName}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                label={LOCALE.lastName}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "brideLastName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={
                                  formData?.brideLastName
                                    ? formData.brideLastName
                                    : ""
                                }
                                error={
                                  formDataErrors.brideLastName !== undefined
                                }
                                helperText={formDataErrors.brideLastName}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="bride-birth-date"
                                    label={LOCALE.brideBirthDate}
                                    value={formData.brideBirthDate}
                                    onChange={(date) =>
                                      handleChangeDate(date, "brideBirthDate")
                                    }
                                    error={
                                      formDataErrors.brideBirthDate !==
                                      undefined
                                    }
                                    helperText={formDataErrors.brideBirthDate}
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                autoComplete={false}
                                size="small"
                                value={
                                  formData?.brideCountry?.name
                                    ? formData?.brideCountry?.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("brideCountry", newValue);
                                }}
                                inputValue={countrySearch}
                                onInputChange={(event, newInputValue) => {
                                  setCountrySearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={countries?.map(
                                  (country) => country.name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.country_born}
                                    fullWidth
                                    error={
                                      formDataErrors.brideCountry !== undefined
                                    }
                                    helperText={formDataErrors.brideCountry}
                                    onFocus={onFocus}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            {LOCALE && LOCALE.getLanguage() === "al" && (
                              <Grid item xs={12}>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  disabled={true}
                                  label={`${LOCALE.country_born} (SQ)`}
                                  value={formData?.brideCountry?.nameAl ?? ""}
                                />
                              </Grid>
                            )}

                            {formData?.brideCountry?.id ===
                            defaultCountry.id ? (
                              <>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    noOptionsText={LOCALE.noOptionsText}
                                    size="small"
                                    value={
                                      formData?.bridePlaceBirth?.name
                                        ? formData.bridePlaceBirth?.name
                                        : ""
                                    }
                                    onChange={(event, newValue) => {
                                      handleChange("bridePlaceBirth", newValue);
                                    }}
                                    inputValue={placeSearch}
                                    onInputChange={(event, newInputValue) => {
                                      setPlaceSearch(
                                        Transliteration.toUpperCyrilic(
                                          newInputValue
                                        )
                                      );
                                    }}
                                    options={places?.map((place) => place.name)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={LOCALE.place_born}
                                        fullWidth
                                        error={
                                          formDataErrors.bridePlaceBirth !==
                                          undefined
                                        }
                                        helperText={
                                          formDataErrors.bridePlaceBirth
                                        }
                                        onFocus={onFocus}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.place_born} (SQ)`}
                                      value={
                                        formData?.bridePlaceBirth?.nameAl ?? ""
                                      }
                                    />
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.place_born}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    error={
                                      formDataErrors.bridePlaceBirthManual !==
                                      undefined
                                    }
                                    helperText={
                                      formDataErrors.bridePlaceBirthManual
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        "bridePlaceBirthManual",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    value={formData?.bridePlaceBirthManual}
                                  />
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.place_born} (SQ)`}
                                      value={
                                        formData?.bridePlaceBirthManual
                                          ? Transliteration.macedonianToAlbanianTransliteration(
                                              formData?.bridePlaceBirthManual
                                            )
                                          : ""
                                      }
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 2 && (
                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      <Grid container>
                        <Hidden smUp>
                          <Grid item xs={12}>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            ></Divider>
                          </Grid>
                        </Hidden>
                        <Grid item xs={false} md={4}></Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ textAlign: "center" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <TextField
                                label={LOCALE.embg}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange("groomEmbg", e.target.value)
                                }
                                value={
                                  formData?.groomEmbg ? formData.groomEmbg : ""
                                }
                                error={formDataErrors.groomEmbg !== undefined}
                                helperText={formDataErrors.groomEmbg}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                label={LOCALE.firstName}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "groomFirstName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={
                                  formData?.groomFirstName
                                    ? formData.groomFirstName
                                    : ""
                                }
                                error={
                                  formDataErrors.groomFirstName !== undefined
                                }
                                helperText={formDataErrors.groomFirstName}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField
                                label={LOCALE.lastName}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "groomLastName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={
                                  formData?.groomLastName
                                    ? formData.groomLastName
                                    : ""
                                }
                                error={
                                  formDataErrors.groomLastName !== undefined
                                }
                                helperText={formDataErrors.groomLastName}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="bride-birth-date"
                                    label={LOCALE.groomBirthDate}
                                    value={formData.groomBirthDate}
                                    onChange={(date) =>
                                      handleChangeDate(date, "groomBirthDate")
                                    }
                                    error={
                                      formDataErrors.groomBirthDate !==
                                      undefined
                                    }
                                    helperText={formDataErrors.groomBirthDate}
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                autoComplete={false}
                                size="small"
                                value={
                                  formData?.groomCountry?.name
                                    ? formData?.groomCountry?.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("groomCountry", newValue);
                                }}
                                inputValue={countrySearch}
                                onInputChange={(event, newInputValue) => {
                                  setCountrySearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={countries?.map(
                                  (country) => country.name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.country_born}
                                    fullWidth
                                    error={
                                      formDataErrors.groomCountry !== undefined
                                    }
                                    helperText={formDataErrors.groomCountry}
                                    onFocus={onFocus}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            {LOCALE && LOCALE.getLanguage() === "al" && (
                              <Grid item xs={12}>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  disabled={true}
                                  label={`${LOCALE.country_born} (SQ)`}
                                  value={formData?.groomCountry?.nameAl ?? ""}
                                />
                              </Grid>
                            )}

                            {formData.groomCountry.id === defaultCountry.id ? (
                              <>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    noOptionsText={LOCALE.noOptionsText}
                                    size="small"
                                    value={
                                      formData?.groomPlaceBirth?.name
                                        ? formData.groomPlaceBirth?.name
                                        : ""
                                    }
                                    onChange={(event, newValue) => {
                                      handleChange("groomPlaceBirth", newValue);
                                    }}
                                    inputValue={placeSearch}
                                    onInputChange={(event, newInputValue) => {
                                      setPlaceSearch(
                                        Transliteration.toUpperCyrilic(
                                          newInputValue
                                        )
                                      );
                                    }}
                                    options={places?.map((place) => place.name)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={LOCALE.place_born}
                                        fullWidth
                                        error={
                                          formDataErrors.groomPlaceBirth !==
                                          undefined
                                        }
                                        helperText={
                                          formDataErrors.groomPlaceBirth
                                        }
                                        onFocus={onFocus}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.place_born} (SQ)`}
                                      value={
                                        formData?.groomPlaceBirth?.nameAl ?? ""
                                      }
                                    />
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.place_born}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    error={
                                      formDataErrors.groomPlaceBirthManual !==
                                      undefined
                                    }
                                    helperText={
                                      formDataErrors.groomPlaceBirthManual
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        "groomPlaceBirthManual",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    value={formData?.groomPlaceBirthManual}
                                  />
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.place_born} (SQ)`}
                                      value={
                                        formData?.groomPlaceBirthManual
                                          ? Transliteration.macedonianToAlbanianTransliteration(
                                              formData?.groomPlaceBirthManual
                                            )
                                          : ""
                                      }
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 3 && (
                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      <Grid container>
                        <Hidden smUp>
                          <Grid item xs={12}>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            ></Divider>
                          </Grid>
                        </Hidden>
                        <Grid item xs={false} md={4}></Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ textAlign: "center" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    disableToolbar
                                    disableFuture
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="bride-birth-date"
                                    label={LOCALE.marriageDate}
                                    value={formData.marriageDate}
                                    onChange={(date) =>
                                      handleChangeDate(date, "marriageDate")
                                    }
                                    error={
                                      formDataErrors.marriageDate !== undefined
                                    }
                                    helperText={formDataErrors.marriageDate}
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                autoComplete={false}
                                size="small"
                                value={
                                  formData?.marriageCountry?.name
                                    ? formData?.marriageCountry?.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("marriageCountry", newValue);
                                }}
                                inputValue={countrySearch}
                                onInputChange={(event, newInputValue) => {
                                  setCountrySearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={countries?.map(
                                  (country) => country.name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.marriageCountry}
                                    fullWidth
                                    error={
                                      formDataErrors.marriageCountry !==
                                      undefined
                                    }
                                    helperText={formDataErrors.marriageCountry}
                                    onFocus={onFocus}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            {LOCALE && LOCALE.getLanguage() === "al" && (
                              <Grid item xs={12}>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  disabled={true}
                                  label={`${LOCALE.marriageCountry} (SQ)`}
                                  value={
                                    formData?.marriageCountry?.nameAl ?? ""
                                  }
                                />
                              </Grid>
                            )}

                            {formData?.marriageCountry?.id !==
                            defaultCountry.id ? (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.marriagePlaceManual}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    error={
                                      formDataErrors.marriagePlaceManual !==
                                      undefined
                                    }
                                    helperText={
                                      formDataErrors.marriagePlaceManual
                                    }
                                    onChange={(e) => {
                                      handleChange(
                                        "marriagePlaceManual",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    value={formData?.marriagePlaceManual}
                                  />
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.marriageCountry} (SQ)`}
                                      value={
                                        formData?.marriagePlaceManual
                                          ? Transliteration.macedonianToAlbanianTransliteration(
                                              formData?.marriagePlaceManual
                                            )
                                          : ""
                                      }
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            ) : (
                              ""
                            )}

                            <Grid item xs={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                size="small"
                                value={
                                  formData?.marriagePlace?.name
                                    ? formData.marriagePlace?.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("marriagePlace", newValue);
                                }}
                                inputValue={placeSearch}
                                onInputChange={(event, newInputValue) => {
                                  setPlaceSearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={places?.map((place) => place.name)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.marriagePlace}
                                    fullWidth
                                    error={
                                      formDataErrors.marriagePlace !== undefined
                                    }
                                    helperText={formDataErrors.marriagePlace}
                                    onFocus={onFocus}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>

                            {LOCALE && LOCALE.getLanguage() === "al" && (
                              <Grid item xs={12}>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  disabled={true}
                                  label={`${LOCALE.marriagePlace} (SQ)`}
                                  value={formData?.marriagePlace?.nameAl ?? ""}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 4 && (
                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      <Grid container spacing={2}>
                        <Hidden smUp>
                          <Grid item xs={12}>
                            <Divider
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                              }}
                            ></Divider>
                          </Grid>
                        </Hidden>
                        <Grid item xs={false} md={4}></Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ textAlign: "center" }}
                        >
                          <Grid container spacing={2}>
                            {currentUser?.proposerType == "ORGANIZATION" &&
                              currentUser?.institution?.institutionType ==
                                2 && (
                                <Grid item xs={12}>
                                  <TextField
                                    label={"Број на предмет (правен интерес)"}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) =>
                                      handleChange(
                                        "legalInterestNumber",
                                        e.target.value
                                      )
                                    }
                                    value={formData?.legalInterestNumber}
                                    error={
                                      formDataErrors.legalInterestNumber !==
                                      undefined
                                    }
                                    helperText={
                                      formDataErrors.legalInterestNumber
                                    }
                                  ></TextField>
                                </Grid>
                              )}
                            <Grid item xs={12} md={12}>
                              <FormControl
                                disabled={loading}
                                variant="outlined"
                                fullWidth
                                size="small"
                              >
                                <InputLabel>{LOCALE.pickup_way}</InputLabel>
                                <Select
                                  value={formData.pickUpWayCert}
                                  onChange={(e) =>
                                    handleChange(
                                      "pickUpWayCert",
                                      e.target.value
                                    )
                                  }
                                  label={LOCALE.pickup_way}
                                >
                                  <MenuItem value="PICKUP_WAY_UVMK">
                                    {LOCALE.pickup_way_uvmk}
                                  </MenuItem>
                                  {currentUser?.proposerType !=
                                    "ORGANIZATION" && (
                                    <MenuItem value="PICKUP_WAY_POSTOFFICE">
                                      {LOCALE.pickup_way_postoffice}
                                    </MenuItem>
                                  )}
                                  {(currentUser?.nationalEid !== null ||
                                    currentUser?.proposerType ==
                                      "ORGANIZATION") &&
                                    formData.typeRequest != 1 && (
                                      <MenuItem value="PICKUP_WAY_ELECTRONIC_CERT">
                                        {LOCALE.pickup_way_el}
                                      </MenuItem>
                                    )}
                                </Select>
                              </FormControl>
                            </Grid>
                            {formData.pickUpWayCert === "PICKUP_WAY_UVMK" && (
                              <>
                                <Grid item xs={12} md={12}>
                                  <Autocomplete
                                    noOptionsText={LOCALE.noOptionsText}
                                    size="small"
                                    disabled={loading}
                                    value={
                                      formData?.deliveryRegistryArea?.title
                                        ? formData?.deliveryRegistryArea?.title
                                        : ""
                                    }
                                    onChange={(event, newValue) => {
                                      handleChange(
                                        "deliveryRegistryArea",
                                        newValue
                                      );
                                    }}
                                    inputValue={deliveryRegistryAreaSearch}
                                    onInputChange={(event, newInputValue) => {
                                      setDeliveryRegistryAreaSearch(
                                        Transliteration.toUpperCyrilic(
                                          newInputValue
                                        )
                                      );
                                    }}
                                    options={registryAreas?.map(
                                      (regArea) => regArea.title
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={LOCALE.pickup_way_reg_area}
                                        fullWidth
                                        error={
                                          formDataErrors.deliveryRegistryArea
                                        }
                                        helperText={
                                          formDataErrors.deliveryRegistryArea
                                        }
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.delivery_place} (SQ)`}
                                      value={
                                        formData?.deliveryRegistryArea
                                          ?.titleAl ?? ""
                                      }
                                    />
                                  </Grid>
                                )}
                              </>
                            )}
                            {formData.pickUpWayCert ===
                              "PICKUP_WAY_POSTOFFICE" && (
                              <React.Fragment>
                                <Grid item xs={12} md={12}>
                                  <Autocomplete
                                    disabled={loading}
                                    noOptionsText={LOCALE.noOptionsText}
                                    size="small"
                                    value={
                                      formData.deliveryPlace.name
                                        ? formData.deliveryPlace.name
                                        : ""
                                    }
                                    onChange={(event, newValue) => {
                                      handleChange("deliveryPlace", newValue);
                                    }}
                                    inputValue={placeSearch}
                                    onInputChange={(event, newInputValue) => {
                                      setPlaceSearch(
                                        Transliteration.toUpperCyrilic(
                                          newInputValue
                                        )
                                      );
                                    }}
                                    options={places?.map((place) => place.name)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={LOCALE.delivery_place}
                                        fullWidth
                                        error={
                                          formDataErrors.deliveryPlace !==
                                          undefined
                                        }
                                        helperText={
                                          formDataErrors.deliveryPlace
                                        }
                                        onFocus={onFocus}
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.delivery_place} (SQ)`}
                                      value={
                                        formData?.deliveryPlace?.nameAl ?? ""
                                      }
                                    />
                                  </Grid>
                                )}

                                <Grid item xs={12} md={12}>
                                  <TextField
                                    label={LOCALE.delivery_address}
                                    size="small"
                                    fullWidth
                                    disabled={loading}
                                    variant="outlined"
                                    onChange={(e) =>
                                      handleChange(
                                        "deliveryAddress",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      )
                                    }
                                    value={formData?.deliveryAddress}
                                    error={
                                      formDataErrors.deliveryAddress !==
                                      undefined
                                    }
                                    helperText={formDataErrors.deliveryAddress}
                                  ></TextField>
                                </Grid>

                                {LOCALE && LOCALE.getLanguage() === "al" && (
                                  <Grid item xs={12}>
                                    <TextField
                                      size="small"
                                      variant="outlined"
                                      fullWidth
                                      disabled={true}
                                      label={`${LOCALE.delivery_address} (SQ)`}
                                      value={
                                        formData?.deliveryAddress
                                          ? Transliteration.macedonianToAlbanianTransliteration(
                                              formData?.deliveryAddress
                                            )
                                          : ""
                                      }
                                    />
                                  </Grid>
                                )}
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {currentUser?.proposerType === "ORGANIZATION" ? (
                    activeStep === 5 ? (
                      <>
                        <Grid item xs={2} />
                        <Grid item xs={8} style={{ textAlign: "center" }}>
                          {saving && (
                            <>
                              <CircularProgress color="secondary" />
                              <p>
                                <strong>Генерирање...</strong>
                              </p>
                            </>
                          )}
                          {ecertProcessStatus !== "NOT_GENERATED" && (
                            <>
                              <Grid container spacing={2}>
                                {ecertProcessStatus ===
                                "SIGNED_AND_UPLOADED" ? (
                                  <Grid item xs={12}>
                                    <Alert severity="success">
                                      <>
                                        <span>
                                          {LOCALE.electronic_doc_signed_success}
                                        </span>
                                      </>
                                    </Alert>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12}>
                                    <Alert severity="info">
                                      <>
                                        <span>
                                          {
                                            LOCALE.electronic_doc_generated_success
                                          }
                                        </span>
                                      </>
                                    </Alert>
                                  </Grid>
                                )}

                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      paddingTop: "25px",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <Button
                                      color="secondary"
                                      onClick={() => {
                                        downloadGeneratedDocument();
                                      }}
                                      fullWidth
                                      size="small"
                                    >
                                      <Icon style={{ marginRight: "10px" }}>
                                        description
                                      </Icon>
                                      <strong>{LOCALE.gen_doc_preview}</strong>
                                    </Button>
                                  </div>
                                </Grid>

                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      paddingTop: "25px",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <Button
                                      color="primary"
                                      size="small"
                                      disabled={
                                        ecertProcessStatus ===
                                        "SIGNED_AND_UPLOADED"
                                      }
                                      variant="contained"
                                      onClick={() => {
                                        signDocument();
                                      }}
                                    >
                                      {LOCALE.sign}
                                    </Button>
                                  </div>
                                </Grid>
                                {currentUser?.proposerType === "ORGANIZATION" &&
                                  currentUser?.institution?.institutionType !=
                                    2 && (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ textAlign: "center" }}
                                    >
                                      <br></br>
                                      <Alert severity="info">
                                        <AlertTitle>
                                          {LOCALE.warning}!
                                        </AlertTitle>
                                        <>
                                          <span>
                                            {LOCALE.upload_polnomosno}
                                          </span>
                                        </>
                                      </Alert>
                                      <br></br>
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          style={{
                                            alignItems: "center",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Upload
                                            onUploadComponentClose={(files) =>
                                              onUploadComponentClose(files)
                                            }
                                          ></Upload>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )}
                              </Grid>
                            </>
                          )}
                          <Grid item xs={2} />
                        </Grid>
                      </>
                    ) : activeStep === 6 ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid md={2} xs={false}></Grid>
                          <Grid md={8} xs={12}>
                            <PaymentComponent
                              reqMarriageCert={persistedRequest}
                            ></PaymentComponent>
                          </Grid>
                          <Grid md={2} xs={false}></Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  {activeStep === 5 &&
                    currentUser?.proposerType !== "ORGANIZATION" && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item md={2} xs={false}></Grid>
                          <Grid item md={8} xs={12}>
                            <PaymentComponent
                              reqMarriageCert={persistedRequest}
                            ></PaymentComponent>
                          </Grid>
                          <Grid item md={2} xs={false}></Grid>
                        </Grid>
                      </Grid>
                    )}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Divider></Divider>
                    {currentUser?.proposerType !== "ORGANIZATION" &&
                      activeStep !== 0 &&
                      activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {currentUser?.proposerType === "ORGANIZATION" &&
                      activeStep !== 0 &&
                      activeStep !== steps.length - 1 &&
                      activeStep !== steps.length - 2 && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={
                          loading ||
                          saving ||
                          (ecertProcessStatus !== "SIGNED_AND_UPLOADED" &&
                            activeStep === 5) ||
                          (activeStep === 5 &&
                            currentUser?.proposerType === "ORGANIZATION" &&
                            currentUser?.institution?.institutionType != 2 &&
                            powerOfAttorneyScan?.length === 0)
                        }
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => {
                          if (
                            AuthService.getCurrentUser()?.proposerType ===
                            "ORGANIZATION"
                          )
                            handleNextStepLegalEntity();
                          else handleNextStep();
                        }}
                      >
                        {LOCALE.next}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() =>
                          setRedirectTo(
                            "/marriages/requestMarriageCertificate/" +
                              formData.id
                          )
                        }
                      >
                        {LOCALE.requestPreview}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() =>
                          (window.location.href = `${
                            SETTINGS.API_URL
                          }/payment/initialization?reasonType=marriage&rel=${
                            persistedRequest.id
                          }&access_token=${
                            AuthService.getToken().access_token
                          }`)
                        }
                      >
                        {LOCALE.i_agree}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
