import React, { useEffect } from "react";
import MomentUtils from "@date-io/moment";
import {
  Grid,
  Typography,
  Container,
  Grow,
  Paper,
  LinearProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Divider,
  Hidden,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Button,
  Tooltip,
  Icon,
  DialogContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";
import { moment } from "../../App";
import { GetMimeType } from "../../common/helpers/GetMimeType";
import { FilesRepository } from "../../repositories/FilesRepository";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import "moment/locale/mk"; // without this line it didn't work
import "moment/locale/sq"; // without this line it didn't work
import { history } from "../..";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { CountryRepository } from "../../repositories/CountryRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { Transliteration } from "../../common/helpers/Transliteration";
import { notifyShowErrorMessage } from "../../common/actions/CommonActions";
import PaymentComponent from "../payment/PaymentComponent";
import { RequestMarriageCertificateRepository } from "../../repositories/RequestMarriageCertificateRepository";
import { RequestMarriageCertificateDataValidator } from "./RequestMarriageCertificateValidator";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { SETTINGS } from "../../properties/ApiProps";
import Upload from "../../common/components/Upload";

const useStyles = makeStyles(styles);

export default function RequestMarriageCertificateEdit() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [globalFormError, setGlobalFormError] = React.useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState();
  const [countries, setCountries] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const [changeDetected, setChangeDetected] = React.useState(false);
  const [powerOfAttorneyScan, setPowerOfAttorneyScan] = React.useState([]);
  const [ecertProcessStatus, setEcertProcessStatus] =
    React.useState("NOT_GENERATED");
  const [generatedDocumentURL, setGeneratedDocumentURL] = React.useState();
  const [generatedDocumentFile, setGeneratedDocumentFile] = React.useState();
  const [countrySearch, setCountrySearch] = React.useState("");
  const [places, setPlaces] = React.useState([]);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [placeBirthSearchGroom, setPlaceBirthSearchGroom] = React.useState("");
  const [placeBirthSearchBride, setPlaceBirthSearchBride] = React.useState("");
  const [deliveryPlaceSearch, setDeliveryPlaceSearch] = React.useState("");
  const [countryBrideSearch, setCountryBrideSearch] = React.useState("");
  const [countryGroomSearch, setCountryGroomSearch] = React.useState("");
  const [placeMarriageSearch, setPlaceMarriageSearch] = React.useState("");
  const [countryMarriageSearch, setCountryMarriageSearch] = React.useState("");
  const [mode, setMode] = React.useState("EDIT");
  const [printDialog, setPrintDialog] = React.useState(false);

  const defaultCountry = {
    countryCode: "807",
    id: "0",
    name: "СЕВЕРНА МАКЕДОНИЈА",
    nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
    nameEn: "REPUBLIC OF NORTH MACEDONIA",
    printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
    printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
    printNameEn: "REPUBLIC OF NORTH MACEDONIA",
  };

  const [formData, setFormData] = React.useState({
    certForChild: "false",
    typeRequest: "0",

    brideEmbg: "",
    brideFirstName: "",
    brideLastName: "",
    brideCountry: defaultCountry,
    bridePlaceBirth: { name: "" },
    bridePlaceBirthManual: "",
    brideBirthDate: moment(),

    groomEmbg: "",
    groomFirstName: "",
    groomLastName: "",
    groomCountry: defaultCountry,
    groomPlaceBirth: { name: "" },
    groomPlaceBirthManual: "",
    groomBirthDate: moment(),

    marriageDate: moment(),
    marriageCountry: defaultCountry,
    marriagePlace: { name: "" },
    marriagePlaceManual: "",

    pickUpWayCert: "PICKUP_WAY_UVMK",
    registryArea: {},
    deliveryRegistryArea: {},
    deliveryPlace: { name: "" },
    deliveryAddress: "",
  });
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");

  React.useEffect(() => {
    if (AuthService.getCurrentUser() == null) {
      setRedirectTo("/login");
    } else {
      loadFormData(id);
    }
  }, []);

  const onUploadComponentClose = (files) => {
    setPowerOfAttorneyScan(files);
  };

  const loadFormData = (id) => {
    setLoading(true);
    RequestMarriageCertificateRepository.get(id)
      .then((res) => {
        let data = formData;
        data = { ...res.data };
        setFormData(data);
        FilesRepository.checkSignedRequestCertificate(data.id, "MARRIAGES")
          .then((res) => {
            if (res.data) {
              setChangeDetected(false);
            } else {
              setChangeDetected(true);
            }
          })
          .catch((err) => {
            setChangeDetected(true);
          });
        FilesRepository.getPowerOfAttorneyScan(data.id, "MARRIAGES")
          .then((res) => {
            if (res.data) {
              let blob = new Blob([res.data]);
              function callback(result) {
                var file = new File(
                  [blob],
                  `${data.id}.${result?.split("/")[1]}`,
                  {
                    type: result,
                  }
                );
                let tmp = [...powerOfAttorneyScan];
                tmp.push(file);
                setPowerOfAttorneyScan(tmp);
              }
              GetMimeType.getType(blob, callback);
            } else {
              setPowerOfAttorneyScan([]);
            }
          })
          .catch((err) => {
            setPowerOfAttorneyScan([]);
          });
        setLoading(false);
      })
      .catch((err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
      });
  };

  const signDocument = () => {
    if (typeof AuctaSignerApp === "function") {
      setLoading(true);
      console.log(window.ext);
      var reader = new FileReader();
      reader.readAsDataURL(generatedDocumentFile);
      reader.onloadend = function () {
        var base64data = reader.result.split(",")[1];
        // console.log(base64data);
        window.ext.signAndCertPDF(
          base64data,
          null,
          null,
          false,
          0,
          400,
          75,
          530,
          135,
          false,
          "null",
          (status, message) => {
            signAndCertCallback(status, message);
          }
        );
      };
    } else {
      setPrintDialog(false);
      setLoading(false);
      setGlobalFormError(
        "Ве молиме инсталирајте ги потребните алатки за дигитално потпишување"
      );
    }
  };

  const uploadSignedContent = (signedContent) => {
    FilesRepository.uploadSignedRequestCertificate(
      signedContent,
      formData.id,
      "MARRIAGES"
    )
      .then((res) => {
        if (res.data === true) {
          setGlobalFormError();
          setEcertProcessStatus("SIGNED_AND_UPLOADED");
          setLoading(false);
          setChangeDetected(false);
        } else {
          setEcertProcessStatus("GENERATED");
          setPrintDialog(false);
          setGlobalFormError(
            "Грешка при прикачување на дигитално потпишаниот документ"
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setEcertProcessStatus("GENERATED");
        setPrintDialog(false);
        setGlobalFormError(
          "Грешка при прикачување на дигитално потпишаниот документ"
        );
        setLoading(false);
      });
  };

  function signAndCertCallback(status, message) {
    if (status === false && message.status === "Error") {
      if (message.error === "No valid certificates.") {
        setGlobalFormError(
          "Не е избран валиден сертификат за дигитално потпишување"
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else if (
        message.error.includes("The action was cancelled by the user.")
      ) {
        setGlobalFormError("Потпишувањето беше откажано на ваше барање");
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else {
        setGlobalFormError(
          "Грешка при потпишување на електронскиот документ. Ве молиме пријавете го овој проблем кај администраторот."
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      }
    } else if (status === true) {
      // console.log(status);
      // console.log(message);
      fetch(message.blobURL).then((r) => {
        r.blob().then((blob) => {
          // console.log(blob);
          let file = new Blob([blob], { type: "application/pdf" });
          uploadSignedContent(file);
        });
      });
    }
  }

  const downloadGeneratedDocument = () => {
    window.open(generatedDocumentURL, "_blank");
  };

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  useEffect(() => {
    UsersRepository.fetchCurrentUser().then(
      (res) => {
        setCurrentUser(res.data);
      },
      (err) => {
        setRedirectTo("/login");
      }
    );
    getActiveRegistryAreas();
  }, []);

  useEffect(() => {
    if (countryMarriageSearch?.length > 1) {
      getCountries(countryMarriageSearch);
    }
  }, [countryMarriageSearch]);

  useEffect(() => {
    if (countryGroomSearch?.length > 1) {
      getCountries(countryGroomSearch);
    }
  }, [countryGroomSearch]);

  useEffect(() => {
    if (countryBrideSearch?.length > 1) {
      getCountries(countryBrideSearch);
    }
  }, [countryBrideSearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeBirthSearchGroom?.length > 1) {
      getPlaces(placeBirthSearchGroom);
    }
  }, [placeBirthSearchGroom]);

  useEffect(() => {
    if (deliveryPlaceSearch?.length > 1) {
      getPlaces(deliveryPlaceSearch);
    }
  }, [deliveryPlaceSearch]);

  useEffect(() => {
    if (placeBirthSearchBride?.length > 1) {
      getPlaces(placeBirthSearchBride);
    }
  }, [placeBirthSearchBride]);

  useEffect(() => {
    if (placeMarriageSearch?.length > 1) {
      getPlaces(placeMarriageSearch);
    }
  }, [placeMarriageSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const handleChange = (name, value) => {
    let tmp = { ...formData };
    if (name === "marriageCountry") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "brideCountry") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "groomCountry") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "groomPlaceBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "bridePlaceBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "marriagePlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryPlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryRegistryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.title === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else if (name === "brideEmbg") {
      if (value.length >= 7) {
        tmp.brideBirthDate = EmbgToDate.getDate(value);
      }
      tmp[name] = value;
      setFormData(tmp);
    } else if (name === "groomEmbg") {
      if (value.length >= 7) {
        tmp.groomBirthDate = EmbgToDate.getDate(value);
      }
      tmp[name] = value;
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
    setChangeDetected(true);
  };

  const generateCertificate = () => {
    setGlobalFormError();
    setSaving(true);
    setPrintDialog(false);
    setGlobalFormError();
    axios({
      url: `${SETTINGS.API_URL}/requestMarriageCertificates/generate/${id}`,
      method: "POST",
      params: {
        access_token: AuthService.getToken().access_token,
      },
      responseType: "blob",
    })
      .then((res) => {
        // console.log(res.data);
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setEcertProcessStatus("GENERATED");
        setGeneratedDocumentFile(file);
        setGeneratedDocumentURL(fileURL);
        setPrintDialog(true);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err.message);
        setSaving(false);
        setGlobalFormError("Грешка при генерирање на документот");
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!validateRequestMarriageCertificateData(formData)) return;
    RequestMarriageCertificateRepository.update(formData)
      .then((res) => {
        setFormData(res.data);
        setLoading(false);
        setMode("PAYMENT");
      })
      .catch((err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
      });
  };

  const handleSubmitLegalEntity = () => {
    setLoading(true);
    if (!validateRequestMarriageCertificateData(formData)) return;
    RequestMarriageCertificateRepository.update(formData)
      .then((res) => {
        setFormData(res.data);
        setLoading(false);
        generateCertificate();
      })
      .catch((err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
      });
  };

  const validateRequestMarriageCertificateData = (formData) => {
    if (!RequestMarriageCertificateDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await RequestMarriageCertificateDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      setGlobalFormError(null);
      return true;
    }
  };

  const handleChangeDate = (date, name) => {
    let data = { ...formData };
    data[name] = date;
    setFormData(data);
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  const backToEdit = () => {
    setMode("EDIT");
  };

  const initializePayment = () => {
    window.location.href = `${
      SETTINGS.API_URL
    }/payment/initialization?reasonType=marriage&rel=${
      formData.id
    }&access_token=${AuthService.getToken().access_token}`;
  };
  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {LOCALE.request_marriage_certificate_full}
              </Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                {mode === "EDIT" && (
                  <Grid container spacing={3} style={{ padding: "20px" }}>
                    {globalFormError && (
                      <Grid item xs={12}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                    )}
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item md={12} xs={12}>
                            <FormControl
                              component="fieldset"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                            >
                              <RadioGroup
                                row
                                aria-label="typeRequest"
                                name="typeRequest"
                                value={formData.typeRequest}
                                onChange={(e) =>
                                  handleChange("typeRequest", e.target.value)
                                }
                                size="small"
                              >
                                <FormControlLabel
                                  value={"BASIC"}
                                  control={<Radio size="small" />}
                                  label={LOCALE.regular_cert}
                                />
                                <FormControlLabel
                                  value={"INTERNATIONAL"}
                                  control={<Radio size="small" />}
                                  label={LOCALE.internationale_cert}
                                />
                                <FormControlLabel
                                  value={"MULTILINGUAL"}
                                  control={<Radio size="small" />}
                                  label={LOCALE.multilingual_cert}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              label={LOCALE.brideEMBG}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange("brideEmbg", e.target.value)
                              }
                              value={
                                formData?.brideEmbg ? formData.brideEmbg : ""
                              }
                              error={formDataErrors.brideEmbg !== undefined}
                              helperText={formDataErrors.brideEmbg}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              label={LOCALE.brideFirstName}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "brideFirstName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={
                                formData?.brideFirstName
                                  ? formData.brideFirstName
                                  : ""
                              }
                              error={
                                formDataErrors.brideFirstName !== undefined
                              }
                              helperText={formDataErrors.brideFirstName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              label={LOCALE.brideLastName}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "brideLastName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={
                                formData?.brideLastName
                                  ? formData.brideLastName
                                  : ""
                              }
                              error={formDataErrors.brideLastName !== undefined}
                              helperText={formDataErrors.brideLastName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormControl
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                            >
                              <MuiPickersUtilsProvider
                                disabled={
                                  loading || formData?.paymentStatus === "PAID"
                                }
                                libInstance={moment}
                                utils={MomentUtils}
                              >
                                <KeyboardDatePicker
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  disableToolbar
                                  format="DD/MM/YYYY"
                                  margin="dense"
                                  inputVariant="outlined"
                                  id="bride-birth-date"
                                  label={LOCALE.brideBirthDate}
                                  value={formData.brideBirthDate}
                                  onChange={(date) =>
                                    handleChangeDate(date, "brideBirthDate")
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              noOptionsText={LOCALE.noOptionsText}
                              autoComplete={false}
                              size="small"
                              value={
                                formData?.brideCountry?.name
                                  ? formData?.brideCountry?.name
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                handleChange("brideCountry", newValue);
                              }}
                              inputValue={countryBrideSearch}
                              onInputChange={(event, newInputValue) => {
                                setCountryBrideSearch(
                                  Transliteration.toUpperCyrilic(newInputValue)
                                );
                              }}
                              options={countries?.map(
                                (country) => country.name
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={LOCALE.country_bride}
                                  fullWidth
                                  error={
                                    formDataErrors.brideCountry !== undefined
                                  }
                                  helperText={formDataErrors.brideCountry}
                                  onFocus={onFocus}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          {LOCALE && LOCALE.getLanguage() === "al" && (
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                label={`${LOCALE.country_bride} (SQ)`}
                                value={formData?.brideCountry?.nameAl ?? ""}
                              />
                            </Grid>
                          )}
                          {formData?.brideCountry?.id === defaultCountry.id ? (
                            <>
                              <Grid item xs={12} md={12}>
                                <Autocomplete
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  value={
                                    formData?.bridePlaceBirth?.name
                                      ? formData.bridePlaceBirth?.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("bridePlaceBirth", newValue);
                                  }}
                                  inputValue={placeBirthSearchBride}
                                  onInputChange={(event, newInputValue) => {
                                    setPlaceBirthSearchBride(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.bridePlaceBirth}
                                      fullWidth
                                      error={
                                        formDataErrors.bridePlaceBirth !==
                                        undefined
                                      }
                                      helperText={
                                        formDataErrors.bridePlaceBirth
                                      }
                                      onFocus={onFocus}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.bridePlaceBirth} (SQ)`}
                                    value={
                                      formData?.bridePlaceBirth?.nameAl ?? ""
                                    }
                                  />
                                </Grid>
                              )}
                            </>
                          ) : (
                            <React.Fragment>
                              <Grid item xs={12} md={12}>
                                <TextField
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  label={LOCALE.place_born}
                                  size="small"
                                  variant="outlined"
                                  autoComplete="false"
                                  fullWidth={true}
                                  error={
                                    formDataErrors.bridePlaceBirthManual !==
                                    undefined
                                  }
                                  helperText={
                                    formDataErrors.bridePlaceBirthManual
                                  }
                                  onChange={(e) => {
                                    handleChange(
                                      "bridePlaceBirthManual",
                                      Transliteration.toUpperCyrilic(
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={formData?.bridePlaceBirthManual}
                                />
                              </Grid>
                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.bridePlaceBirth} (SQ)`}
                                    value={
                                      formData?.bridePlaceBirthManual
                                        ? Transliteration.macedonianToAlbanianTransliteration(
                                            formData?.bridePlaceBirthManual
                                          )
                                        : ""
                                    }
                                  />
                                </Grid>
                              )}
                            </React.Fragment>
                          )}
                          <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={MomentUtils}
                              >
                                <KeyboardDatePicker
                                  disableToolbar
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  disableFuture
                                  format="DD/MM/YYYY"
                                  margin="dense"
                                  inputVariant="outlined"
                                  id="bride-birth-date"
                                  label={LOCALE.marriageDate}
                                  value={formData.marriageDate}
                                  onChange={(date) =>
                                    handleChangeDate(date, "marriageDate")
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              noOptionsText={LOCALE.noOptionsText}
                              autoComplete={false}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              value={
                                formData?.marriageCountry?.name
                                  ? formData?.marriageCountry?.name
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                handleChange("marriageCountry", newValue);
                              }}
                              inputValue={countryMarriageSearch}
                              onInputChange={(event, newInputValue) => {
                                setCountryMarriageSearch(
                                  Transliteration.toUpperCyrilic(newInputValue)
                                );
                              }}
                              options={countries?.map(
                                (country) => country.name
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={LOCALE.marriageCountry}
                                  fullWidth
                                  error={
                                    formDataErrors.marriageCountry !== undefined
                                  }
                                  helperText={formDataErrors.marriageCountry}
                                  onFocus={onFocus}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          {LOCALE && LOCALE.getLanguage() === "al" && (
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                label={`${LOCALE.marriageCountry} (SQ)`}
                                value={formData?.marriageCountry?.nameAl ?? ""}
                              />
                            </Grid>
                          )}

                          {formData?.marriageCountry?.id !==
                          defaultCountry.id ? (
                            <React.Fragment>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  label={LOCALE.marriagePlaceManual}
                                  size="small"
                                  variant="outlined"
                                  autoComplete="false"
                                  fullWidth={true}
                                  error={
                                    formDataErrors.marriagePlaceManual !==
                                    undefined
                                  }
                                  helperText={
                                    formDataErrors.marriagePlaceManual
                                  }
                                  onChange={(e) => {
                                    handleChange(
                                      "marriagePlaceManual",
                                      Transliteration.toUpperCyrilic(
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={formData?.marriagePlaceManual}
                                />
                              </Grid>

                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.marriagePlaceManual} (SQ)`}
                                    value={
                                      formData?.marriagePlaceManual
                                        ? Transliteration.macedonianToAlbanianTransliteration(
                                            formData?.marriagePlaceManual
                                          )
                                        : ""
                                    }
                                  />
                                </Grid>
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          <Grid item xs={12}>
                            <Autocomplete
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              noOptionsText={LOCALE.noOptionsText}
                              size="small"
                              value={
                                formData?.marriagePlace?.name
                                  ? formData.marriagePlace?.name
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                handleChange("marriagePlace", newValue);
                              }}
                              inputValue={placeMarriageSearch}
                              onInputChange={(event, newInputValue) => {
                                setPlaceMarriageSearch(
                                  Transliteration.toUpperCyrilic(newInputValue)
                                );
                              }}
                              options={places?.map((place) => place.name)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={LOCALE.marriagePlace}
                                  fullWidth
                                  error={
                                    formDataErrors.marriagePlace !== undefined
                                  }
                                  helperText={formDataErrors.marriagePlace}
                                  onFocus={onFocus}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          {LOCALE && LOCALE.getLanguage() === "al" && (
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                label={`${LOCALE.marriagePlace} (SQ)`}
                                value={formData?.marriagePlace?.nameAl ?? ""}
                              />
                            </Grid>
                          )}
                          {/* Bride and marriage data column */}
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={false}
                            md={12}
                            style={{ paddingBottom: "50px" }}
                          ></Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              label={LOCALE.groomEMBG}
                              size="small"
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange("groomEmbg", e.target.value)
                              }
                              value={
                                formData?.groomEmbg ? formData.groomEmbg : ""
                              }
                              error={formDataErrors.groomEmbg !== undefined}
                              helperText={formDataErrors.groomEmbg}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              label={LOCALE.groomFirstName}
                              size="small"
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "groomFirstName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={
                                formData?.groomFirstName
                                  ? formData.groomFirstName
                                  : ""
                              }
                              error={
                                formDataErrors.groomFirstName !== undefined
                              }
                              helperText={formDataErrors.groomFirstName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              label={LOCALE.groomLastName}
                              size="small"
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "groomLastName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={
                                formData?.groomLastName
                                  ? formData.groomLastName
                                  : ""
                              }
                              error={formDataErrors.groomLastName !== undefined}
                              helperText={formDataErrors.groomLastName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={MomentUtils}
                              >
                                <KeyboardDatePicker
                                  disableToolbar
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  format="DD/MM/YYYY"
                                  margin="dense"
                                  inputVariant="outlined"
                                  id="bride-birth-date"
                                  label={LOCALE.groomBirthDate}
                                  value={formData.groomBirthDate}
                                  onChange={(date) =>
                                    handleChangeDate(date, "groomBirthDate")
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              noOptionsText={LOCALE.noOptionsText}
                              autoComplete={false}
                              size="small"
                              value={
                                formData?.groomCountry?.name
                                  ? formData?.groomCountry?.name
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                handleChange("groomCountry", newValue);
                              }}
                              inputValue={countryGroomSearch}
                              onInputChange={(event, newInputValue) => {
                                setCountryGroomSearch(
                                  Transliteration.toUpperCyrilic(newInputValue)
                                );
                              }}
                              options={countries?.map(
                                (country) => country.name
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={LOCALE.country_groom}
                                  fullWidth
                                  error={
                                    formDataErrors.groomCountry !== undefined
                                  }
                                  helperText={formDataErrors.groomCountry}
                                  onFocus={onFocus}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          {LOCALE && LOCALE.getLanguage() === "al" && (
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                label={`${LOCALE.country_groom} (SQ)`}
                                value={formData?.groomCountry?.nameAl ?? ""}
                              />
                            </Grid>
                          )}

                          {formData.groomCountry.id === defaultCountry.id ? (
                            <>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  value={
                                    formData?.groomPlaceBirth?.name
                                      ? formData.groomPlaceBirth?.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("groomPlaceBirth", newValue);
                                  }}
                                  inputValue={placeBirthSearchGroom}
                                  onInputChange={(event, newInputValue) => {
                                    setPlaceBirthSearchGroom(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.groomPlaceBirth}
                                      fullWidth
                                      error={
                                        formDataErrors.groomPlaceBirth !==
                                        undefined
                                      }
                                      helperText={
                                        formDataErrors.groomPlaceBirth
                                      }
                                      onFocus={onFocus}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>

                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.groomPlaceBirth} (SQ)`}
                                    value={
                                      formData?.groomPlaceBirth?.nameAl ?? ""
                                    }
                                  />
                                </Grid>
                              )}
                            </>
                          ) : (
                            <React.Fragment>
                              <Grid item xs={12}>
                                <TextField
                                  label={LOCALE.place_born}
                                  size="small"
                                  variant="outlined"
                                  autoComplete="false"
                                  fullWidth={true}
                                  error={
                                    formDataErrors.groomPlaceBirthManual !==
                                    undefined
                                  }
                                  helperText={
                                    formDataErrors.groomPlaceBirthManual
                                  }
                                  onChange={(e) => {
                                    handleChange(
                                      "groomPlaceBirthManual",
                                      Transliteration.toUpperCyrilic(
                                        e.target.value
                                      )
                                    );
                                  }}
                                  value={formData?.groomPlaceBirthManual}
                                />
                              </Grid>

                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.groomPlaceBirth} (SQ)`}
                                    value={
                                      formData?.groomPlaceBirthManual
                                        ? Transliteration.macedonianToAlbanianTransliteration(
                                            formData?.groomPlaceBirthManual
                                          )
                                        : ""
                                    }
                                  />
                                </Grid>
                              )}
                            </React.Fragment>
                          )}
                          <Grid item xs={12} md={12}>
                            <FormControl
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <InputLabel>{LOCALE.pickup_way}</InputLabel>
                              <Select
                                value={formData.pickUpWayCert}
                                onChange={(e) =>
                                  handleChange("pickUpWayCert", e.target.value)
                                }
                                label={LOCALE.pickup_way}
                              >
                                <MenuItem value="PICKUP_WAY_UVMK">
                                  {LOCALE.pickup_way_uvmk}
                                </MenuItem>
                                {currentUser?.proposerType !=
                                  "ORGANIZATION" && (
                                  <MenuItem value="PICKUP_WAY_POSTOFFICE">
                                    {LOCALE.pickup_way_postoffice}
                                  </MenuItem>
                                )}
                                {(currentUser?.nationalEid !== null ||
                                  currentUser?.proposerType ==
                                    "ORGANIZATION") &&
                                  formData.typeRequest != 1 && (
                                    <MenuItem value="PICKUP_WAY_ELECTRONIC_CERT">
                                      {LOCALE.pickup_way_el}
                                    </MenuItem>
                                  )}
                              </Select>
                            </FormControl>
                          </Grid>
                          {formData.pickUpWayCert === "PICKUP_WAY_UVMK" && (
                            <>
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  value={
                                    formData?.deliveryRegistryArea?.title
                                      ? formData?.deliveryRegistryArea?.title
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange(
                                      "deliveryRegistryArea",
                                      newValue
                                    );
                                  }}
                                  inputValue={deliveryRegistryAreaSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setDeliveryRegistryAreaSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={registryAreas?.map(
                                    (regArea) => regArea.title
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.pickup_way_reg_area}
                                      fullWidth
                                      error={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      helperText={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.pickup_way_reg_area} (SQ)`}
                                    value={
                                      formData?.deliveryRegistryArea?.titleAl ??
                                      ""
                                    }
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                          {currentUser?.proposerType == "ORGANIZATION" &&
                            currentUser?.institution?.institutionType == 2 && (
                              <Grid item xs={12}>
                                <TextField
                                  label={"Број на предмет (правен интерес)"}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleChange(
                                      "legalInterestNumber",
                                      e.target.value
                                    )
                                  }
                                  value={
                                    formData?.legalInterestNumber
                                      ? formData?.legalInterestNumber
                                      : ""
                                  }
                                  error={
                                    formDataErrors.legalInterestNumber !==
                                    undefined
                                  }
                                  helperText={
                                    formDataErrors.legalInterestNumber
                                  }
                                ></TextField>
                              </Grid>
                            )}
                          {currentUser?.proposerType === "ORGANIZATION" && (
                            <>
                              <Dialog
                                open={printDialog}
                                onClose={() => setPrintDialog(false)}
                                aria-labelledby="form-dialog-title"
                              >
                                <DialogTitle>
                                  Генерирање електронски документ
                                </DialogTitle>
                                <DialogContent>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    {saving && (
                                      <>
                                        <CircularProgress color="secondary" />
                                        <p>
                                          <strong>Генерирање...</strong>
                                        </p>
                                      </>
                                    )}
                                    {ecertProcessStatus !== "NOT_GENERATED" && (
                                      <>
                                        <Grid container spacing={2}>
                                          {ecertProcessStatus ===
                                          "SIGNED_AND_UPLOADED" ? (
                                            <Grid item xs={12}>
                                              <Alert severity="success">
                                                <span>
                                                  Електронскиот документ е
                                                  успешно потпишан.
                                                </span>
                                              </Alert>
                                            </Grid>
                                          ) : (
                                            <Grid item xs={12}>
                                              <Alert severity="info">
                                                <>
                                                  <span>
                                                    Документот е успешно
                                                    генериран и чека на
                                                    дигитален потпис. Ве молиме
                                                    проверете ја точноста на
                                                    податоците.
                                                  </span>
                                                </>
                                              </Alert>
                                            </Grid>
                                          )}
                                          <Grid item xs={12}>
                                            <div
                                              style={{
                                                paddingTop: "25px",
                                                paddingBottom: "25px",
                                              }}
                                            >
                                              <Button
                                                color="secondary"
                                                onClick={() => {
                                                  downloadGeneratedDocument();
                                                }}
                                                fullWidth
                                                size="small"
                                              >
                                                <Icon
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  description
                                                </Icon>
                                                <strong>
                                                  Преглед на генерираниот
                                                  документ
                                                </strong>
                                              </Button>
                                            </div>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <div
                                              style={{
                                                paddingTop: "25px",
                                                paddingBottom: "25px",
                                              }}
                                            >
                                              <Button
                                                color="primary"
                                                size="small"
                                                disabled={
                                                  ecertProcessStatus ===
                                                  "SIGNED_AND_UPLOADED"
                                                }
                                                variant="contained"
                                                onClick={() => {
                                                  signDocument();
                                                }}
                                              >
                                                Потпиши
                                              </Button>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() => {
                                      setPrintDialog(false);
                                    }}
                                    color="secondary"
                                    size="small"
                                  >
                                    Откажи
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </>
                          )}
                          {formData.pickUpWayCert ===
                            "PICKUP_WAY_POSTOFFICE" && (
                            <React.Fragment>
                              <Grid item xs={12} md={12}>
                                <Autocomplete
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  value={
                                    formData?.deliveryPlace?.name
                                      ? formData?.deliveryPlace?.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("deliveryPlace", newValue);
                                  }}
                                  inputValue={deliveryPlaceSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setDeliveryPlaceSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.delivery_place}
                                      fullWidth
                                      error={
                                        formDataErrors.deliveryPlace !==
                                        undefined
                                      }
                                      helperText={formDataErrors.deliveryPlace}
                                      onFocus={onFocus}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField
                                  label={LOCALE.delivery_address}
                                  size="small"
                                  fullWidth
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleChange(
                                      "deliveryAddress",
                                      Transliteration.toUpperCyrilic(
                                        e.target.value
                                      )
                                    )
                                  }
                                  value={formData?.deliveryAddress}
                                  error={
                                    formDataErrors.deliveryAddress !== undefined
                                  }
                                  helperText={formDataErrors.deliveryAddress}
                                ></TextField>
                              </Grid>
                            </React.Fragment>
                          )}
                          <Grid item xs={12}>
                            {formData?.pickUpWayCert ===
                              "PICKUP_WAY_ELECTRONIC_CERT" &&
                            formData?.paymentStatus === "PAID" &&
                            formData?.verificationCode !== null ? (
                              <a
                                href={
                                  "/external/verify/certificate/" +
                                  formData.verificationCode
                                }
                                target="blank"
                              >
                                <Button
                                  fullWidth
                                  color="secondary"
                                  size="small"
                                  variant="contained"
                                  style={{ float: "right" }}
                                  startIcon={
                                    <Icon style={{ color: "white" }}>
                                      cloud_download
                                    </Icon>
                                  }
                                >
                                  Преземи
                                </Button>
                              </a>
                            ) : (
                              ""
                            )}
                          </Grid>
                          {currentUser?.proposerType === "ORGANIZATION" &&
                            currentUser?.institution?.institutionType != 2 &&
                            formData?.paymentStatus !== "PAID" && (
                              <Grid
                                item
                                xs={12}
                                style={{ textAlign: "center" }}
                              >
                                <br></br>
                                <Alert severity="info">
                                  <AlertTitle>{LOCALE.warning}!</AlertTitle>
                                  <>
                                    <span>
                                      Овде треба да прикачите полномошно од
                                      лицето за кое барате извод.
                                    </span>
                                  </>
                                </Alert>
                                <br></br>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Upload
                                      onUploadComponentClose={(files) =>
                                        onUploadComponentClose(files)
                                      }
                                      files={powerOfAttorneyScan}
                                    ></Upload>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          {/* Groom data column */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={3} style={{ padding: "20px" }}>
                  {mode === "PAYMENT" && (
                    <Grid item xs={12}>
                      <PaymentComponent reqMarriageCert={formData} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {currentUser?.proposerType === "ORGANIZATION" &&
                    changeDetected &&
                    formData?.paymentStatus !== "PAID" ? (
                      <>
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          style={{ float: "right" }}
                          onClick={() => {
                            handleSubmitLegalEntity();
                          }}
                        >
                          Генерирај документ
                        </Button>
                      </>
                    ) : (
                      mode === "EDIT" &&
                      formData?.paymentStatus !== "PAID" && (
                        <Button
                          disabled={
                            loading ||
                            formData?.paymentStatus === "PAID" ||
                            (currentUser?.proposerType === "ORGANIZATION" &&
                              ecertProcessStatus !== "SIGNED_AND_UPLOADED" &&
                              changeDetected) ||
                            (currentUser?.proposerType === "ORGANIZATION" &&
                              powerOfAttorneyScan?.length === 0)
                          }
                          color="secondary"
                          size="small"
                          variant="contained"
                          style={{ float: "right" }}
                          onClick={() => {
                            if (currentUser.proposerType === "ORGANIZATION") {
                              setLoading(true);
                              FilesRepository.uploadPowerOfAttorneyScan(
                                powerOfAttorneyScan,
                                formData.id,
                                "MARRIAGES"
                              ).then(
                                (res) => {
                                  setLoading(false);
                                  setGlobalFormError(null);
                                  setMode("PAYMENT");
                                },
                                (err) => {
                                  dispatch(
                                    notifyShowErrorMessage(
                                      ErrorMessageResolver.resolve(err)
                                    )
                                  );
                                  setGlobalFormError(
                                    ErrorMessageResolver.resolve(err)
                                  );
                                  setLoading(false);
                                }
                              );
                            } else handleSubmit();
                          }}
                        >
                          {LOCALE.pay}
                        </Button>
                      )
                    )}
                    {mode === "EDIT" && (
                      <Button
                        disabled={loading}
                        color="default"
                        size="small"
                        variant="contained"
                        onClick={() => setRedirectTo("/orders")}
                      >
                        {LOCALE.back}
                      </Button>
                    )}
                    {mode === "PAYMENT" && (
                      <Button
                        disabled={loading}
                        color="default"
                        size="small"
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        onClick={() => backToEdit()}
                      >
                        {LOCALE.back}
                      </Button>
                    )}
                    {mode === "PAYMENT" && (
                      <Button
                        disabled={loading}
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{ marginTop: "10px", float: "right" }}
                        onClick={() => initializePayment()}
                      >
                        {LOCALE.iAgree}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
